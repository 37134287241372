/**
 * Created by john on 22/10/16.
 */
'use strict';

/* exported
bookingsCtrl
 */

function bookingsCtrl($scope, bookings, user, Restangular, $window, $location, BookingViewModal, fetchBookings, $uibModal, APPROVAL_METHOD, BookingsSrv, appConfig, PRODUCT_TYPES) {
  $scope.bookings = bookings;
  $scope.user = user;
  $scope.filters = {
    domain: 'all',
    sort: 'bookedAt:-1',
    date: 'created',
    payment: 'paid;authorized',
    teamEmail: user.teamEmail,
    status: '',
    amendStatus: '',
  };

  $scope.approvalMethod = APPROVAL_METHOD;
  $scope.productTypes = PRODUCT_TYPES;
  $scope.appConfig = appConfig;

  // this function is hack to support object like cities and companies which uses _id
  function formatFiltersForFetch(filters){
    var result = {};
    Object.keys(filters).forEach(function (key){
      if (filters[key]) {
        if (key === 'country'){
          result[key] = encodeURIComponent((filters[key] && filters[key]._id) || filters[key]);
        } else {
          result[key] = (filters[key] && filters[key]._id) || filters[key];
        }
      }
    });
    return result;
  }

  // On init if domain is selected display it
  var search = $location.search();

  if (search.domain) {
    $scope.filters.domain = search.domain;
  }

  // On init if status is selected display it
  if (search.status) {
    $scope.filters.status = search.status;
  }

  if (search.manual) {
    $scope.filters.manual = search.manual;
  }

  if (search.amendStatus) {
    $scope.filters.amendStatus = search.amendStatus;
  }

  // On init if reference is selected display it
  if (search.references) {
    $scope.filters.references = search.references;
  }

  if (search.extraInfo) {
    $scope.filters.extraInfo = search.extraInfo;
  }

  // On init if email is selected display it
  if (search.email) {
    $scope.filters.email = search.email;
  }

  if (search.region) {
    $scope.filters.region = search.region;
  }

  // On init if company is selected display it
  if (search.company) {
    $scope.filters.company = {_id: search.company};
  }

  if (search.supplier) {
    $scope.filters.supplier = {_id: search.supplier};
  }

  // On init if departure is selected display it
  if (search.departure) {
    $scope.filters.departure = {_id: search.departure};
  }

  // On init if arrival is selected display it
  if (search.arrival) {
    $scope.filters.arrival = {_id: search.arrival};
  }

  if (search.createdFromExplicit) {
    $scope.filters.createdFromExplicit = new Date(search.createdFromExplicit);
  }

  if (search.createdToExplicit) {
    $scope.filters.createdToExplicit = new Date(search.createdToExplicit);
  }

  if (search.departureFrom) {
    $scope.filters.departureFrom = new Date(search.departureFrom);
  }

  if (search.departureTo) {
    $scope.filters.departureTo = new Date(search.departureTo);
  }

  if (search.country) {
    $scope.filters.country = search.country;
  }

  if (search.approveMethod) {
    $scope.filters.approveMethod = search.approveMethod;
  }

  if (search.payment) {
    $scope.filters.payment = search.payment;
  }

  if (search.cartReference) {
    $scope.filters.cartReference = search.cartReference;
  }

  if (search.isHandled) {
    $scope.filters.isHandled = search.isHandled;
  }

  if (search.booking) {
    BookingViewModal.show(search.booking, user);
  }

  if (search.sort) {
    $scope.filters.sort = search.sort;
  }

  if (search.tripId) {
    $scope.filters.tripId = search.tripId;
  }

  if (search.eventId) {
    $scope.filters.eventId = search.eventId;
  }

  if (search.productId) {
    $scope.filters.productId = search.productId;
  }

  if (search.supplierBookingCode) {
    $scope.filters.supplierBookingCode = search.supplierBookingCode;
  }

  if (search.firstName) {
    $scope.filters.firstName = search.firstName;
  }

  if (search.lastName) {
    $scope.filters.lastName = search.lastName;
  }

  if (search.customerStatus) {
    $scope.filters.customerStatus = search.customerStatus;
  }

  /**
   * Paging
   */
    // On init if page is above 1

  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.pageChanged = function pageChanged() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;
    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.bookings = bookings;
    });
  };

  $scope.$on('resetFilters', function(event, filters) {
    $scope.filters = Object.assign({}, filters);
    $scope.currentPage = 1;

    fetchBookings(filters).then(function(bookings) {
      $scope.bookings = bookings;
    });
  });

  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }
    $location.search(search);
  });

  /**
   * Filters
   */
  $scope.$on('filters.domain', function(event, data) {
    $scope.filters.domain = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
    });
  });

  $scope.$on('filters.status', function(event, data) {
    $scope.filters.status = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.references', function(event, data) {
    $scope.filters.references = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.cartReference', function(event, data) {
    $scope.filters.cartReference = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.extraInfo', function(event, data) {
    $scope.filters.extraInfo = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.supplierBookingCode', function(event, data) {
    $scope.filters.supplierBookingCode = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.email', function(event, data) {
    $scope.filters.email = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.region', function(event, data) {
    $scope.filters.region = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.firstName', function(event, data) {
    $scope.filters.firstName = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.lastName', function(event, data) {
    $scope.filters.lastName = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.company', function(event, data) {
    $scope.filters.company = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.supplier', function(event, data) {
    $scope.filters.supplier = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.payment', function(event, data) {
    $scope.filters.payment = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.approveMethod', function(event, data) {
    $scope.filters.approveMethod = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.country', function(event, data) {
    $scope.filters.country = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.departure', function(event, data) {
    $scope.filters.departure = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.arrival', function(event, data) {
    $scope.filters.arrival = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.createdFromExplicit', function(event, data) {
    $scope.filters.createdFromExplicit = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.createdToExplicit', function(event, data) {
    $scope.filters.createdToExplicit = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.departureFrom', function(event, data) {
    $scope.filters.departureFrom = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.departureTo', function(event, data) {
    $scope.filters.departureTo = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.manual', function(event, data) {
    $scope.filters.manual = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$watch('filters.sort', function(sort, previousValue) {
    if (sort === previousValue) {
      return;
    }

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.bookings = bookings;
      
    });

    var search = $location.search();

    search.sort = sort;

    $location.search(search);
  });

  $scope.$on('filters.amendStatus', function(event, data) {
    $scope.filters.amendStatus = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.customerStatus', function(event, data) {
    $scope.filters.customerStatus = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.$on('filters.isHandled', function(event, data) {
    $scope.filters.isHandled = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    fetchBookings(formatFiltersForFetch($scope.filters)).then(function(bookings) {
      $scope.currentPage = 1;
      $scope.bookings = bookings;
      
    });
  });

  $scope.showBooking = function(booking) {
    BookingViewModal.show(booking._id, user);

    var search = $location.search();
    search.booking = booking._id;
    $location.search(search);
  };

  $scope.updateInSupplierRecords = function(booking, event) {
    event.stopPropagation();

    Restangular
      .all('v2/bookings/' + booking._id + '/update-in-supplier-records')
      .customPUT({ isUpdatedInSupplierRecords: booking.isUpdatedInSupplierRecords })
      .catch(function() {
        $scope.$emit('notify', {type: 'error', title: 'Bookings', message: 'Error updating booking'});
      });
  };

  $scope.handleInProgress = function(booking) {
    event.stopPropagation();
    if (booking.inProgressBy) {
      booking.inProgressBy = null;
    } else {
      booking.inProgressBy = user.displayName;
    }
    Restangular
      .all('v2/bookings/' + booking._id + '/update-in-progress')
      .customPUT({ inProgressBy: booking.inProgressBy  })
      .catch(function() {
        $scope.$emit('notify', {type: 'error', title: 'Bookings', message: 'Error updating booking'});
      });
  };

  $scope.getCSV = function() {
    var queryString = angular.merge({limit: 0}, $scope.filters);

    Restangular
      .one('bookings')
      .get(queryString, {'accept': 'text/csv'})
      .then(function(response) {
        var file = new Blob([response], { type: 'text/csv;charset=utf-8' });
        $window.saveAs(file, 'bookings.csv');
      })
      .catch(function() {
        $scope.$emit('notify', {type: 'error', title: 'Download', message: 'Error downloading CSV'});
      });
  };

  $scope.getBookingTitle = function(booking) {
    if (booking.items[0].productType === 'line') {
      var title = booking.items[0].trip.fromId.city.name + ' to ' + booking.items[0].trip.toId.city.name;

      if (booking.items.length === 2) {
        title += ' and return';
      }

      return title;
    } else {
      return booking.items[0].product.denomination + ' | from ' + booking.items[0].trip.fromId.city.name;
    }
  };

  $scope.getBookingFromStationName = function(booking){
    return booking.items[0].trip.fromId.city.name;
  };

  $scope.getBookingToStationName = function(booking){
    return booking.items[0].trip.toId.city.name;
  };

  $scope.openBulkVouchersSend = function (){
    $uibModal
      .open({
        templateUrl: 'views/bookings/bulk-vouchers-send.html',
        controller: 'BulkVouchersSendCtrl',
        resolve: {
          bookings: [function() {
            return $scope.bookings;
          }],
          filters: [function() {
            return $scope.filters;
          }],
          Restangular: Restangular,
        }
      })
      .result
      .then(function() {
        console.log('bulk vouchers sent');
      });
  };

  $scope.openBulkEmailSend = function (){
    $uibModal
      .open({
        templateUrl: 'views/bookings/bulk-email-send.html',
        controller: 'BulkEmailSendCtrl',
        resolve: {
          bookings: [function() {
            return $scope.bookings;
          }],
          filters: [function() {
            return $scope.filters;
          }],
          Restangular: Restangular,
        }
      })
      .result
      .then(function() {
        console.log('bulk email sent');
      });
  };

  $scope.isBookingPaid = function (booking){
    return ['paid', 'refund', 'authorized'].includes(booking.payment.status);
  };

  $scope.isBookingManual = function (booking) {
    return _.get(booking, 'items[0].product.manualBookings.operations', false) || _.get(booking, 'items[0].product.manualBookings.support', false);
  };

  $scope.getBookingsCSVBySupplier = function(customerStatus) {
    $uibModal
      .open({
        templateUrl: 'views/bookings/get-passenger-list.html',
        controller: 'GetPassengerListCtrl',
        windowClass: 'get-passenger-list-modal'
      })
      .result
      .then(function (params) {
        if(params && params.selectedDate && params.calculationBasedOn) {
          return BookingsSrv.getPassengerList({ _id: user.company ,isSupplier:true }, params.selectedDate, customerStatus, params.calculationBasedOn, true);
        }
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Download', message: _.get(err, 'data.message', 'Error downloading CSV')});
      });
  };
}

'use strict';

/* exported
bookingCancelCtrl
 */

function bookingCancelCtrl($scope, booking, transactions, $uibModalInstance, $analytics, cancellationReasons) {
  $scope.cancellationReasons = cancellationReasons;
  $scope.booking = booking;
  $scope.basicCurrency = $scope.booking.basicCurrency;
  $scope.isNotUsd = $scope.basicCurrency !== 'USD';
  $scope.cancellationPolicy = null;
  $scope.isSupplierCancellationFailed = false;
  $scope.refund = {};
  $scope.cancellationType = "";
  $scope.cancellationReason = "";
  $scope.refundType = {
    full: 'full',
    partial: 'partial',
    none: 'none',
  }
  const maxRefundFeePercent = 100;
  const extendedPolicyFeePercent = 10;
  $scope.isFullRefund = false;
  $scope.includeFees = false;
  $scope.onAmountChange = function() {
    if (_.isNumber($scope.refund.fee)) {
      $scope.refund.fee = null;
    }
  }

  function updateRefundAmount(fee) {
    // The + is a trick to convert back to a Number
    const amountToCalc = !$scope.includeFees ? $scope.itemsPrice : ($scope.booking.extendedPolicy && !$scope.isFullRefund ? $scope.itemsPrice: $scope.booking.price.amount);
    $scope.refund.amount = +(amountToCalc * (maxRefundFeePercent - fee) / maxRefundFeePercent).toFixed(2);
    $scope.refund.costRefundInSupplierCurrency = +(($scope.dueToSupplier) * (maxRefundFeePercent - fee) / maxRefundFeePercent).toFixed(2);
    if($scope.refund.amount > $scope.booking.price.amount) {
      $scope.$emit('notify', {type: 'error', title: 'Cancel', message: 'Amout of refund can\'t be larger than the booking price'});    
    }
  }

  $scope.$watch('refund.fee', function(fee, prevFee) {
    if (fee > maxRefundFeePercent) {
      $scope.refund.fee = prevFee;
    }
    else if (_.isNumber(fee)) {
      updateRefundAmount(fee);
    }
  })


/*
 * Monitors changes to the 'includeFees' checkbox,
 * which determines whether fees should be included in the refund calculation.
 */
  $scope.$watch('includeFees', function(newValue, oldValue) {
    if (newValue !== oldValue) {
      const fee = $scope.refund.fee;
      updateRefundAmount(fee);
    }
  });

  var isRefundable = booking.items[0].product.isRefundable;
  const cancellations = _.get(booking.items[0], 'transferData.cancellations') || _.get(booking.items[0], 'product.cancellations');

  if (_.isBoolean(isRefundable)) {
    const hoursBeforeDeparture = moment(booking.items[0].trip.departure.date).diff(moment(), 'hours');
    if (booking.status === 'declined' || (booking.status === 'pending' && booking.customerStatus === 'pending') && !booking.pendingTickets) {
      $scope.refund.fee = 0;
      $scope.refund.type = $scope.refundType.full;
      $scope.refundInfo = 'declined / pending bookings should be fully refunded.';
      $scope.isFullRefund = true;
    } else if (booking.extendedPolicy && isRefundable === false && hoursBeforeDeparture >= 24) {
      $scope.refund.fee = extendedPolicyFeePercent;
      $scope.refund.type = $scope.refundType.partial;
      $scope.refundInfo = 'Cancellation and changes can be made up to 24 hours with 10 percent fee (from trip price).';
    } else if (isRefundable === false) {
      $scope.refund.fee = maxRefundFeePercent;
      $scope.refund.type = $scope.refundType.partial;
      $scope.refundInfo = 'Cancellations are not possible after the tickets have been issued.';
    } else {
      if (cancellations && cancellations.length) {
        var currentTime = new Date().getTime();
        $scope.cancellationPolicy = cancellations.sort(function(a, b){
          var hoursBeforeA = a.timeBefore * (a.unit === 'days' ? 24 : 1);
          var hoursBeforeB = b.timeBefore * (b.unit === 'days' ? 24 : 1);
          return hoursBeforeB - hoursBeforeA;
        }).find(function (policy) {
          var departureTimeWithHoursBefore = new Date(booking.items[0].trip.departure.date);
          departureTimeWithHoursBefore.setHours(
            departureTimeWithHoursBefore.getHours() -
            (policy.unit === 'hours' ? policy.timeBefore : policy.timeBefore * 24)
          );
          departureTimeWithHoursBefore = departureTimeWithHoursBefore.getTime();
          return currentTime < departureTimeWithHoursBefore;
        });
        if (!$scope.cancellationPolicy) {
          $scope.refund.fee = maxRefundFeePercent;
          $scope.refund.type = $scope.refundType.partial;
          $scope.refundInfo = 'Cancellations are no longer possible for this departure date.';
        } else if ($scope.cancellationPolicy.timeBefore > 0 && $scope.cancellationPolicy.fee === 0) {
          $scope.refund.fee = 0;
          $scope.refund.type = $scope.refundType.full;
          $scope.refundInfo = 'Cancellations are free up to ' + $scope.cancellationPolicy.timeBefore + ' ' + $scope.cancellationPolicy.unit + ' before departure.';
        }
        else if ($scope.cancellationPolicy.timeBefore === 0 && $scope.cancellationPolicy.fee > 0) {
          $scope.refund.fee = $scope.cancellationPolicy.fee;
          $scope.refund.type = $scope.refundType.partial;
          $scope.refundInfo = 'Cancellations are possible with a ' + $scope.cancellationPolicy.fee + '% cancellation fee.';
        }
        else if ($scope.cancellationPolicy.timeBefore > 0 && $scope.cancellationPolicy.fee > 0) {
          $scope.refund.fee = $scope.cancellationPolicy.fee;
          $scope.refund.type = $scope.refundType.partial;
          $scope.refundInfo = 'Cancellations are possible with a ' + $scope.cancellationPolicy.fee + '% cancellation fee if you cancel up to ' + $scope.cancellationPolicy.timeBefore + ' ' + $scope.cancellationPolicy.unit + ' before departure.';
        }
      }
    }
  }
  $scope.paidByTraveller = $scope.booking.price.amount;
  $scope.paidByTravellerWithoutFees = $scope.booking.priceWithoutFees.amount;
  $scope.itemsPrice = $scope.booking.items.reduce((totalPrice, item) => totalPrice + item.price.amount, 0).toFixed(2);
  $scope.dueToSupplier = _.get(booking, 'price.transportationOnlyCostInSupplierCurrency') || _.get(booking, 'price.costInSupplierCurrency'); // $scope.booking.price.costInSupplierCurrency;

  $scope.sendMail = true;

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.$watch('refund.type', function(type, prevType) {
    if (type !== prevType) {
      if (type === $scope.refundType.full) {
        $scope.refund.fee = 0;
      } else if (type === $scope.refundType.partial) {
        if ($scope.cancellationPolicy) {
          $scope.refund.fee = $scope.cancellationPolicy.fee;
        }
      }
      else if (type === $scope.refundType.none) {
        $scope.refund.fee = maxRefundFeePercent;
      }
    }
  })

  $scope.cancelBooking = function(params = {}) {
    if ($scope.cancelForm.$invalid) {
      angular.forEach($scope.cancelForm.$error, function (field) {
        angular.forEach(field, function(errorField){
            errorField.$setTouched();
        })
      });
      return;
    }

    if($scope.refund.amount > $scope.booking.price.amount) {
      $scope.$emit('notify', {type: 'error', title: 'Cancel', message: 'Amout of refund can\'t be larger than the booking price'});    
      return;
    }

    var payload = {refundCostCurrency: $scope.booking.price.supplierCurrency};
    if (params.doMarkSupplierApiCancelled){
      payload.doMarkSupplierApiCancelled = true;
    }

    if ($scope.refund.type && $scope.refund.amount !== undefined) {
      payload.refundAmount = $scope.refund.amount.toFixed(2);
      payload.refundCost = ($scope.refund.costRefundInSupplierCurrency || $scope.dueToSupplier).toFixed(2);
    }
    payload.sendMail = $scope.sendMail;

    payload.cancellationType = $scope.cancellationType;
    payload.chosenReason = $scope.cancellationReason;
    if (!payload.cancellationType || !payload.chosenReason) {
      $scope.$emit("notify", {
        type: "error",
        title: "Cancellation reason",
        message:
          "Please provide both cancellation type and reason.",
      });
      return;
    }

    if ($scope.includeFees) {
      payload.includeFees = $scope.includeFees;
    }

    //Calling to v2 functionality
    booking.route = 'v2/bookings';
    booking.post('cancel', payload)
      .then(function(canceledBooking) {

        // Temporary hack
        setTimeout(function() {
          location.reload();
        },1000);

        // $scope.$emit('notify', {type: 'success', title: 'Cancel', message: 'Booking canceled with Success'});
        // canceledBooking.route = 'bookings';
        // var countries = [booking.items[0].trip.fromId.city.country];
        // var cities = [booking.items[0].trip.fromId.city.name];
        //
        // if (booking.items[0].productType === 'line') {
        //   if (booking.items[0].trip.fromId.city._id !== booking.items[0].trip.toId.city._id) {
        //     cities.push(booking.items[0].trip.toId.city.name);
        //   }
        //
        //   if (booking.items[0].trip.fromId.city.country !== booking.items[0].trip.toId.city.country) {
        //     cities.push(booking.items[0].trip.toId.city.country);
        //   }
        // }
        //
        // // Send to mixpanel only if there was a refund
        // try {
        //   if ($scope.refund.type === 'yes') {
        //     $analytics.eventTrack('Refund', {
        //       'Product ID': booking.items[0].product.ecommerce.productId,
        //       'Product Name': booking.items[0].product.ecommerce.name,
        //       'Company': booking.items[0].company.name,
        //       'Countries': countries,
        //       'Cities': cities,
        //       'Amount': $scope.refund.amount,
        //       'Supplier Amount': $scope.refund.supplierAmount / 100,
        //       'Refund Type': $scope.refundType,
        //       'Reason': 'Refund upon cancellation',
        //       'Source': 'Booking Cancel Modal'
        //     });
        //   }
        // } catch (e) {
        //   console.log('Error with mixpanel', e);
        // }

        // $uibModalInstance.close(canceledBooking);
      })
      .catch(function(err) {
        if (params.isSupplierCancellation) {
          $scope.isSupplierCancellationFailed = true;
          $scope.errorMessage = err.data.message;
        } else {
          $scope.$emit('notify', {type: 'error', title: 'Cancel', message: 'Booking cancel failed. <br>' + err.data.message});
        }
      });
    //restoring the route
    booking.route = 'bookings';
  };
}

'use strict';

/* exported
bookingDeclineCtrl
 */

function bookingDeclineCtrl($scope, booking, $uibModalInstance, $analytics, declineSrv, DataCollector, user) {
  $scope.booking = booking;
  $scope.user = user;

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.declineObject = {};

  $scope.resetBlockDeparture = function (){
    delete $scope.declineObject.blockDeparture;
  };

  $scope.showBlockDeparture = function showBlockDeparture() {
    var result = ['fully_booked', 'departure_does_not_exists', 'departure_cancelled'].includes($scope.declineObject.declineReason);
    // enable supplier to block the departure
    if ($scope.declineObject.declineReason === 'fully_booked'){
      $scope.secondLegManualBlockActive = (booking.items.length === 2);
      $scope.firstLegManualBlockActive = (booking.items.length === 1 && booking.items[0].passengers.length > 2) || $scope.secondLegManualBlockActive;
      $scope.noneOptionActive = booking.items[0].passengers.length > 2;
    } else if ($scope.declineObject.declineReason === 'departure_does_not_exists' || $scope.declineObject.declineReason === 'departure_cancelled'){
      $scope.firstLegManualBlockActive = true;
      $scope.secondLegManualBlockActive = true;
      $scope.noneOptionActive = false;
    }
    $scope.autoBlockDeparture = (
      ($scope.declineObject.declineReason === 'fully_booked' && !$scope.firstLegManualBlockActive) ||
      ($scope.declineObject.declineReason === 'departure_does_not_exists' && booking.items.length === 1) ||
      ($scope.declineObject.declineReason === 'departure_cancelled' && booking.items.length === 1)
    );
    if ($scope.autoBlockDeparture){
      $scope.declineObject.blockDeparture = 'firstLeg';
    }

    return result;
  };

  $scope.showDeclineReasonText = function showDeclineReasonText() {
    return ['fully_booked', 'departure_does_not_exists', 'departure_cancelled', 'out_of_credit', 'missing_info_from_client', 'pickup_location_not_supported', 'not_enough_passengers', 'supplier_unresponsive', 'rate_difference', 'not_approved_on_time', 'schedule_is_not_open_yet', 'other'].includes($scope.declineObject.declineReason);
  };

  $scope.showCustomPlaceholder = function showCustomPlaceholder() {
    return ['missing_info_from_client', 'pickup_location_not_supported', 'not_enough_passengers', 'supplier_unresponsive', 'rate_difference'].includes($scope.declineObject.declineReason);
  };

  $scope.getBookingPassengersNumber = function (){
    return (booking.items[0].passengers && booking.items[0].passengers.length) || 0;
  };

  $scope.decline = function() {
    if ($scope.declineForm.$invalid) {
      $scope.declineForm.reason.$setTouched();
      if ($scope.declineForm.reasonText) {
        $scope.declineForm.reasonText.$setTouched();
      }
      return;
    }

    if ($scope.showBlockDeparture() && $scope.declineObject.blockDeparture === undefined) {
      return $scope.$emit('notify', {type: 'error', title: 'Invalid', message: 'please choose one of the options.'});
    }
    //Calling to v2 functionality
    booking.route = 'v2/bookings';
    booking.post('decline', $scope.declineObject)
      .then(function(declinedBooking) {

        declinedBooking.route = 'bookings';
        var countries = [booking.items[0].trip.fromId.city.country];
        var cities = [booking.items[0].trip.fromId.city.name];

        if (booking.items[0].productType === 'line') {
          if (booking.items[0].trip.fromId.city._id !== booking.items[0].trip.toId.city._id) {
            cities.push(booking.items[0].trip.toId.city.name);
          }

          if (booking.items[0].trip.fromId.city.country !== booking.items[0].trip.toId.city.country) {
            cities.push(booking.items[0].trip.toId.city.country);
          }
        }

        if ($scope.declineObject.blockDeparture) {
          var props = DataCollector.blockDeparturePropsGenerator.byBooking(booking);
          DataCollector.trackBlockedDeparture(props);
        }

        // Send to mixpanel only if there was a refund
        try {
          $analytics.eventTrack('Decline Booking', {
            'Product ID': booking.items[0].product.ecommerce.productId,
            'Product Name': booking.items[0].product.ecommerce.name,
            'Company': booking.items[0].company.name,
            'Countries': countries,
            'Cities': cities,
            'Reason': declineSrv.formatReason($scope.declineObject.declineReason),
          });
        } catch (e) {
          console.log('Error with mixpanel', e);
        }

        $scope.$emit('notify', {type: 'success', title: 'Decline', message: 'Booking declined with Success'});
        $uibModalInstance.close(declinedBooking);
        setTimeout(function() {
          location.reload();
        },1000);
      })
      .catch(function() {
        $scope.$emit('notify', {type: 'error', title: 'Decline', message: 'Booking decline failed'});
      });
    //restoring the route
    booking.route = 'bookings';
  };
}

'use strict';

/* exported
bookingAmendRequestCtrl
 */

function bookingAmendRequestCtrl($scope, booking, product, Restangular, moment, $uibModalInstance) {
  $scope.booking = booking;
  $scope.product = product;
  $scope.isAmendSubmitted = false;

  $scope.approvalInputs = booking.approvalInputs || {};

  if (!$scope.approvalInputs.departureTrip) {
    $scope.approvalInputs.departureTrip = {
      seatsNumber: [],
      ticketsQrCode: []
    };
  }

  if (!$scope.approvalInputs.returnTrip) {
    $scope.approvalInputs.returnTrip = {
      seatsNumber: [],
      ticketsQrCode: []
    };
  }

  Restangular.all('v2/bookings/' + $scope.booking._id + '/booking-amends').get('')
    .then(function(bookingAmend){

      $scope.bookingAmend = bookingAmend;

    }).catch(function(err) {
      console.log(err.message);
      $scope.$emit('notify', {type: 'error', title: 'Change request', message: 'Failed to get amend request'});
  });

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.amend = function() {

    $scope.isAmendSubmitted = true;

    if ($scope.amendRequestForm.$invalid) {
      return;
    }

    Restangular.all('v2/bookings/' + $scope.booking._id + '/approve-amend/' + $scope.bookingAmend._id).customPUT({approvalInputs: $scope.approvalInputs})
      .then(function(result){

        if (result.paymentStatus === 'approved') {

          location.reload();

          $uibModalInstance.close(result);
        }
        else {
          var message = result.actionAfterDecline === 'keepOriginalBooking' ? "Please note that the client's change request was not applied" : "Please note, the client asked to cancel this booking";
          $scope.$emit('notify', {type: 'error', title: 'Payment failed', message: message});
        }

      }).catch(function(err) {
      console.log(err.message);
      $scope.$emit('notify', {type: 'error', title: 'Change request', message: 'Failed to approve amend'});
    });
  };

  $scope.decline = function() {

    Restangular.all('v2/bookings/' + $scope.booking._id + '/decline-amend/' + $scope.bookingAmend._id).customPUT()
      .then(function(booking){

        location.reload();

        $uibModalInstance.close(booking);

      }).catch(function(err) {
      console.log(err.message);
      $scope.$emit('notify', {type: 'error', title: 'Change request', message: 'Failed to decline amend'});
    });
  };
}

/**
 * Created by john on 13/1/17.
 */
'use strict';

/* exported
bookingApproveCtrl
 */
function bookingApproveCtrl($scope, booking, product, $uibModalInstance, Upload, appConfig, user, ImagesRestangular, isReapprove, moment) {

  $scope.isApproveSubmitted = false;

  $scope.approvalInputs = booking.approvalInputs || {};

  if (!$scope.approvalInputs.departureTrip) {
    $scope.approvalInputs.departureTrip = {
      seatsNumber: [],
      ticketsQrCode: []
    };
  }

  if (!$scope.approvalInputs.returnTrip) {
    $scope.approvalInputs.returnTrip = {
      seatsNumber: [],
      ticketsQrCode: []
    };
  }

  function localTimeToDateTimeWithTimezone(time, date, timezone) {
    var startOfDay = moment.tz(date, timezone).startOf('day');
    var timeObject = moment(time);

    return startOfDay.add(moment.duration({
      hours: parseInt(timeObject.format('HH'), 10),
      minutes: parseInt(timeObject.format('mm'), 10)
    }));
  }

  function getExtraOptionDefinition(extraOption, item) {
    var extraOptionDefinitions = product.extras;
    const stationExtras = item.transferData.stationExtras || [];
    return extraOptionDefinitions.find(function(extraOptionDefinition) {
      return extraOptionDefinition._id === extraOption.definition;
    }) || stationExtras.find((extraOptionDefinition)=> extraOptionDefinition._id === extraOption.definition);
  }

  $scope.updatePickup = function (extra, approvedDeparture) {
    // take relevant HOTEL PICKUP item
    var hotelPickupItem = extra.data.find(function (item) {
      return item.name === approvedDeparture.from;
    }) || { time: 0 };
    var time = hotelPickupItem.time * (hotelPickupItem.beforeAfter === 'before' ? -1 : 1);
    var originalDepartureDate;

    // update pickup in relevant BOOKING item
    if ($scope.booking) {
      $scope.booking.items[extra.itemIndex].pickup = {
        time: time,
        location: approvedDeparture.from
      };

      originalDepartureDate = $scope.booking.items[extra.itemIndex].trip.departure;
      if (extra.time === 'pickup') {
        approvedDeparture.time = moment.tz(originalDepartureDate.date, originalDepartureDate.timezone).add(time, 'minutes');
      }
    }
  };


  function generateExtraField(extra, item) {
    var extraOptionDefinition = getExtraOptionDefinition(extra, item);
    var response = { comment: extra.comment, itemIndex: extra.itemIndex };
    var approvedDepartureTime = item.approvedDeparture ? item.approvedDeparture.time : null;
    var departureType = item.approvedDeparture ? item.approvedDeparture.type : 'approved';
    var arrivalType = item.approvedArrival ? item.approvedArrival.type : 'approved';
    var OTHER_STATUS = 'other';

    if (departureType === OTHER_STATUS) {
      response.departureOtherValue = item.approvedDeparture.from;
    }

    if (arrivalType === OTHER_STATUS) {
      response.arrivalOtherValue = item.approvedArrival.to;
    }

    if (approvedDepartureTime) {
      var start = moment().startOf('day');

      var timeValue = start.add(moment.duration({
        hours: parseInt(moment.tz(approvedDepartureTime, item.trip.departure.timezone).format('HH'), 10),
        minutes: parseInt(moment.tz(approvedDepartureTime, item.trip.departure.timezone).format('mm'), 10)
      }));

      response.timeValue = timeValue.toDate();
    }

    switch (extraOptionDefinition.extraType) {
      case 'pickup_airport': {
        Object.assign(response, {
          priority: 1,
          type: extraOptionDefinition.extraType,
          id: extra.definition,
          label: extraOptionDefinition.label + ' in ' + item.trip.fromId.city.name + ' Airport',
          passengerRequest: extra.value,
          stationAddress: item.trip.fromId.address,
          departureTime: item.trip.departure.date,
          timezone: item.trip.departure.timezone,
          time: approvedDepartureTime === item.trip.departure.date || !approvedDepartureTime ? 'original' : 'new',
        });

        break;
      }

      case 'pickup_hotel': {
        Object.assign(response, {
          priority: 1,
          type: extraOptionDefinition.extraType,
          id: extra.definition,
          label: extraOptionDefinition.label + ' in ' + item.trip.fromId.city.name,
          passengerRequest: extra.value,
          stationAddress: item.trip.fromId.address,
          originalDepartureTime: item.trip.departure.date,
          departureTime: item.trip.departure.date,
          timezone: item.trip.departure.timezone,
          status: departureType,
          time: approvedDepartureTime === item.trip.departure.date || !approvedDepartureTime ? 'original' : 'new',
          usePredefinedData: extraOptionDefinition.usePredefinedData,
          data: extraOptionDefinition.data,
        });

        response.time = response.usePredefinedData ? 'pickup' : response.time;

        break;
      }

      case 'pickup_point': {
        Object.assign(response, {
          priority: 1,
          type: extraOptionDefinition.extraType,
          id: extra.definition,
          label: extraOptionDefinition.label + ' in ' + item.trip.fromId.city.name,
          passengerRequest: extra.value,
          stationAddress: item.trip.fromId.address,
          departureTime: item.trip.departure.date,
          timezone: item.trip.departure.timezone,
          time: approvedDepartureTime === item.trip.departure.date || !approvedDepartureTime ? 'original' : 'new',
        });

        break;
      }

      case 'dropoff_airport': {
        Object.assign(response, {
          priority: 2,
          type: extraOptionDefinition.extraType,
          id: extra.definition,
          label: extraOptionDefinition.label + ' in ' + item.trip.toId.city.name + ' Airport',
          passengerRequest: extra.value,
          stationAddress: item.trip.toId.address
        });

        break;
      }

      case 'dropoff_hotel': {
        Object.assign(response, {
          priority: 2,
          type: extraOptionDefinition.extraType,
          id: extra.definition,
          label: extraOptionDefinition.label + ' in ' + item.trip.toId.city.name,
          passengerRequest: extra.value,
          stationAddress: item.trip.toId.address,
          status: arrivalType,
          usePredefinedData: extraOptionDefinition.usePredefinedData,
          data: extraOptionDefinition.data,
        });

        break;
      }

      case 'dropoff_point': {
        Object.assign(response, {
          priority: 2,
          type: extraOptionDefinition.extraType,
          id: extra.definition,
          label: extraOptionDefinition.label + ' in ' + item.trip.toId.city.name,
          passengerRequest: extra.value,
        });

        break;
      }

      case 'upgrade': {
        response = generateUpgrade(extra, extraOptionDefinition);

        break;
      }
    }

    return response;
  }

  function generateUpgrade(extra, extraOptionDefinition) {
    return {
      priority: 3,
      type: extraOptionDefinition.extraType,
      id: extra.definition,
      comment: extra.comment,
      label: extraOptionDefinition.upgrade,
      inputLabel: extraOptionDefinition.inputLabel,
      passengerRequest: extra.value
    };
  }

  function generateExtraFields(item) {
    return item.extraOptions.map(function(extra) {
      return generateExtraField(extra, item);
    })
      .sort(function(a, b) {
        if (a.priority > b.priority) {
          return 1;
        } else if (a.priority < b.priority) {
          return -1;
        } else {
          return 0;
        }
      });
  }

  function formatNewExtraBeforeSubmit(extra, itemNumber) {
    var formattedExtra = {
      comment: extra.comment,
      id: extra.id
    };

    var approvedDeparture = {};
    var approvedArrival = {};

    switch (extra.type) {
      case 'pickup_hotel': {
        approvedDeparture.type = extra.status;
        if (extra.status === 'approved') {
          formattedExtra.approved = true;
          approvedDeparture.from = extra.passengerRequest;
        } else if (extra.status === 'original') {
          formattedExtra.approved = false;
          approvedDeparture.from = extra.stationAddress;
        } else if (extra.status === 'other') {
          formattedExtra.approved = false;
          approvedDeparture.from = extra.departureOtherValue;
        }

        if (extra.time === 'original') {
          approvedDeparture.time = extra.departureTime;
        } else if (extra.time === 'new') {
          approvedDeparture.time = localTimeToDateTimeWithTimezone(extra.timeValue, extra.departureTime, extra.timezone);
        } else if (extra.time === 'pickup') {
          approvedDeparture.time = moment(extra.departureTime);
        }

        $scope.updatePickup(extra, approvedDeparture);

        formattedExtra.approvedDeparture = approvedDeparture;
        break;
      }

      case 'pickup_airport': {
        approvedDeparture.type = extra.status;
        approvedDeparture.from = extra.stationAddress;
        formattedExtra.approved = true;

        if (extra.time === 'original') {
          approvedDeparture.time = extra.departureTime;
        } else if (extra.time === 'new') {
          approvedDeparture.time = localTimeToDateTimeWithTimezone(extra.timeValue, extra.departureTime, extra.timezone);
        }

        formattedExtra.approvedDeparture = approvedDeparture;
        break;
      }

      case 'pickup_point': {
        approvedDeparture.type = extra.status;
        approvedDeparture.from = extra.stationAddress;
        formattedExtra.approved = true;

        if (extra.time === 'original') {
          approvedDeparture.time = extra.departureTime;
        } else if (extra.time === 'new') {
          approvedDeparture.time = localTimeToDateTimeWithTimezone(extra.timeValue, extra.departureTime, extra.timezone);
        }

        formattedExtra.approvedDeparture = approvedDeparture;
        break;
      }

      case 'dropoff_hotel': {
        approvedArrival.type = extra.status;
        if (extra.status === 'approved') {
          formattedExtra.approved = true;
          approvedArrival.to = extra.passengerRequest;
        } else if (extra.status === 'original') {
          approvedArrival.to = extra.stationAddress;
          formattedExtra.approved = false;
        } else if (extra.status === 'other') {
          approvedArrival.to = extra.arrivalOtherValue;
          formattedExtra.approved = false;
        }

        formattedExtra.approvedArrival = approvedArrival;
        break;
      }

      case 'dropoff_airport': {
        approvedArrival.type = extra.status;
        formattedExtra.approved = true;
        approvedArrival.to = extra.stationAddress;
        formattedExtra.approvedArrival = approvedArrival;
        break;
      }

      case 'dropoff_point': {
        approvedArrival.type = extra.status;
        formattedExtra.approved = true;
        approvedArrival.to = extra.stationAddress;
        formattedExtra.approvedArrival = approvedArrival;
        break;
      }

      case 'upgrade': {
        formattedExtra.approved = true;

        if (itemNumber) {
          formattedExtra.itemNumber = itemNumber;
        }

        break;
      }
    }

    return formattedExtra;
  }

  var route = 'approve';

  // In case the product was updated but there were some old bookings with old options we need to force old options
  var bookingExtras = booking.items.reduce(function(acc, current, index) {
    current.extraOptions.forEach(function (extra) {
      extra.itemIndex = index;
    });
    return acc.concat(current.extraOptions);
  }, booking.extraOptions);

  var oldExtras = product.extraOptionDefinitions;

  if (!(bookingExtras && bookingExtras.length > 0 && oldExtras && oldExtras.length && oldExtras.find(function(extra) {
    return extra._id === bookingExtras[0].definition;
  }))) {
    if ((product.extras && product.extras.length) || product.extraOptionDefinitions.length === 0) {
      route = 'approve-new';
    }
  }


  $scope.uploadObject = {
    approvalAttachements: []
  };

  $scope.isNewExtras = route === 'approve-new';

  // Only if new extras
  if ($scope.isNewExtras) {
    $scope.approvedExtras = generateExtraFields(booking.items[0]);

    if (booking.items[1]) {
      $scope.returnApprovedExtras = generateExtraFields(booking.items[1]);
    }

    $scope.globalApprovedExtras = booking.extraOptions.map(function(extra) {
      var extraOptionDefinition = getExtraOptionDefinition(extra, booking.items[0]);

      return generateUpgrade(extra, extraOptionDefinition);
    });
  }

  $scope.uploadProgress = 0;
  $scope.needExtraInfo = product.needExtraInfo;
  $scope.booking = booking;
  $scope.product = booking.items[0].product;
  $scope.mappings = {};
  $scope.isActivity = false;

  if (booking.items[0].approvalInputs && booking.items[0].approvalInputs.length > 0) {
    booking.items[0].approvalInputs.forEach(function(approvalInput) {
      $scope.mappings[approvalInput.templateKey] = approvalInput.value;
    });
  }

  $scope.approvalAttachements = booking.items[0].approvalAttachements || [];
  $scope.voucherAttachments = booking.voucherAttachments || [];

  // Add missing info from approval attachements
  $scope.approvalAttachements.forEach(function(approvalAttachement) {
    ImagesRestangular.one('files/' + approvalAttachement.id).get()
      .then(function(response) {
        approvalAttachement.mimetype = response.mimetype;
        approvalAttachement.name = response.originalName;
      });
  });

  $scope.isLoading = false;

  $scope.approve = function() {
    var payload = {};
    $scope.isApproveSubmitted = true;
    var hasInvalidTime = false;

    ($scope.approvedExtras || []).forEach(function(extra) {
        if (extra.time === 'new' && extra.timeValue instanceof Date && extra.departureTime && extra.timezone) {
            
            var timezone = extra.timezone;
            var departureTime = extra.departureTime;
            var dateInTimezone = moment.utc(departureTime).tz(timezone);

            var momentInNewTimezone = moment(extra.timeValue);

            var formattedTime1 = dateInTimezone.format('HH:mm');
            var formattedTime2 = momentInNewTimezone.format('HH:mm');

            var time1 = moment(formattedTime1, 'HH:mm');
            var time2 = moment(formattedTime2, 'HH:mm');

            var difference = Math.abs(time1.diff(time2, 'minutes')) / 60;          
            extra.isTimeInvalid = difference > 2;

            if (extra.isTimeInvalid) {
                hasInvalidTime = true;
            }
        } else {
            extra.isTimeInvalid = false;
        }
  });

    if ($scope.booking.extraInfo) {
      payload.extraInfo = $scope.booking.extraInfo;
    }

    if (hasInvalidTime || $scope.approvedExtrasForm.$invalid) {
      console.log('Form invalid');
      return;
    }

    if (isReapprove) {
      payload.reapprove = true;
    }

    payload.extras = ($scope.approvedExtras || []).map(function(extra) {
      return formatNewExtraBeforeSubmit(extra, 1);
    });

    if ($scope.returnApprovedExtras) {
      payload.extras = payload.extras.concat(($scope.returnApprovedExtras || []).map(function(extra) {
        return formatNewExtraBeforeSubmit(extra, 2);
      }));
    }

    payload.extras = payload.extras.concat(($scope.globalApprovedExtras || []).map(function(extra) {
      return formatNewExtraBeforeSubmit(extra);
    }));

    payload.pickups = booking.items.map(function(item) {
      return item.pickup;
    });

    payload.dropOffs = booking.items.map(function(item) {
      return item.dropOff;
    });

    payload.voucherAttachments = $scope.voucherAttachments;

    payload.approvalInputs = $scope.approvalInputs;

    //Calling to v2 functionality
    booking.route = 'v2/bookings';
    booking.post('approve', payload)
      .then(() => {
        setTimeout(function() {
          location.reload();
        },2000);
      })
      .catch(() => {
        $scope.$emit('notify', {type: 'error', title: 'Approve', message: 'Booking approve failed'});
        $scope.isLoading = false;
      });
    //restoring the route
    booking.route = 'bookings';
  };

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };


  // NEW VOUCHER ATTACHMENTS
  $scope.$watch('uploadObject.voucherAttachments', function() {
    $scope.uploadVoucherAttachments($scope.uploadObject.voucherAttachments);
  });

  $scope.uploadVoucherAttachments = function (voucherAttachments) {
    if (voucherAttachments && voucherAttachments.length && !voucherAttachments.$error) {
      var types = voucherAttachments.map(function() {
        return 'attachment';
      });

      Upload.upload({
        url: appConfig.imagesServiceBaseUrl + '/upload/files',
        arrayKey: '', // http://stackoverflow.com/questions/32917617/multer-not-accepting-files-in-array-format-gives-unexpected-filed-error
        data: {
          owner: user._id,
          line: $scope.product._id,
          bookingReference: booking.reference,
          types: types,
          files: voucherAttachments
        }
      }).then(function(resp) {
        $scope.voucherAttachments = $scope.voucherAttachments.concat(resp.data.map(function(voucherAttachment) {
          return {
            id: voucherAttachment._id,
            url: voucherAttachment._id + '.' + voucherAttachment.ext,
            name: voucherAttachment.originalName,
            mimetype: voucherAttachment.mimetype
          };
        }));
      }, function() {
        $scope.$emit('notify', {type: 'error', title: 'Upload', message: 'Failed to upload voucher attachment'});
      }, function(evt) {
        $scope.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
      });
    }
  };

  $scope.getVoucherAttachmentUrl = function(voucherAttachment) {
    return appConfig.imagesServiceBaseUrl + '/media/files/' + voucherAttachment.url;
  };

  $scope.removeVoucherAttachment = function(voucherAttachment) {
    $scope.voucherAttachments.splice($scope.voucherAttachments.indexOf(voucherAttachment), 1);
  };

  // OLD Approval Attachments (SOON DEPRECATED)
  $scope.$watch('uploadObject.approvalAttachements', function() {
    $scope.uploadTickets($scope.uploadObject.approvalAttachements);
  });

  $scope.uploadTickets = function (approvalAttachements) {
    if (approvalAttachements && approvalAttachements.length && !approvalAttachements.$error) {
      var types = approvalAttachements.map(function() {
        return 'attachement';
      });

      Upload.upload({
        url: appConfig.imagesServiceBaseUrl + '/upload/files',
        arrayKey: '', // http://stackoverflow.com/questions/32917617/multer-not-accepting-files-in-array-format-gives-unexpected-filed-error
        data: {
          owner: user._id,
          line: $scope.product._id,
          bookingReference: booking.reference,
          types: types,
          files: approvalAttachements
        }
      }).then(function(resp) {
        $scope.approvalAttachements = $scope.approvalAttachements.concat(resp.data.map(function(approvalAttachement) {
          return {
            id: approvalAttachement._id,
            url: approvalAttachement._id + '.' + approvalAttachement.ext,
            name: approvalAttachement.originalName,
            mimetype: approvalAttachement.mimetype
          };
        }));
      }, function() {
        $scope.$emit('notify', {type: 'error', title: 'Upload', message: 'Failed to upload attachement'});
      }, function(evt) {
        $scope.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
      });
    }
  };

  $scope.getApprovalAttachementUrl = function(approvalAttachement) {
    return appConfig.imagesServiceBaseUrl + '/media/files/' + approvalAttachement.url;
  };

  $scope.removeAttachement = function(approvalAttachement) {
    $scope.approvalAttachements.splice($scope.approvalAttachements.indexOf(approvalAttachement), 1);
  };
}

'use strict';

/* exported
 bookingSearchCtrl,
 bookingSearchModalCtrl
 */

function bookingSearchCtrl($scope, $state) {
  $scope.bookingSearch = {
    passengers: 1,
    productType: 'line'
  };

  $scope.onProductSelected = function(product, date) {
    var params = {
      bookingId: 'new',
      productId: product._id
    };
    params.product = JSON.stringify(product);

    // It's case of route
    if (product.from) {
      params.from = product.from._id;
    }

    if (product.productType === 'online') {
      if (product.to) {
        params.to = product.to._id;
      }
      if (_.get(product, 'from.city.slug')) {
        params.fromSlug = product.from.city.slug;
      }
      if (_.get(product, 'to.city.slug')) {
        params.toSlug = product.to.city.slug;
      }
      if (product.transportReference) {
        params.transportReference = product.transportReference;
      }
    }
    if (date) {
      params.date = date;
    }
    $state.go('root.editBooking', params);
  };
}

function bookingSearchModalCtrl($scope, basicCurrency, $uibModalInstance) {
  $scope.basicCurrency = basicCurrency;
  $scope.bookingSearch = {
    passengers: 1,
    productType: 'line',
  };

  $scope.onProductSelected = function (product) {
    $uibModalInstance.close({ product });
  };
}

'use strict';

/* exported
bookingViewModalService
 */

function bookingViewModalService($uibModal, $state, $location) {
  /* jshint validthis: true */
  this.show = function (bookingId, user) {
    $uibModal.open({
      templateUrl: 'views/bookings/view.html',
      controller: 'ViewBookingCtrl',
      resolve: {
        booking: ['Restangular', function (Restangular) {
          return Restangular.one('bookings', bookingId).get();
        }],
        notes: ['Restangular', function (Restangular) {
          return user.shouldSeeFullAdmin ? Restangular.one('notes').get({booking: bookingId}) : [];
        }
        ],
        product: ['Restangular', function (Restangular) {
          return Restangular.one('bookings', bookingId).get()
            .then(function (booking) {
              var route = 'lines';
              if (_.get(booking, 'items[0].productType') === 'online') {
                return booking.items[0].product;
              }
              var productId = booking.items[0].product._id;

              return Restangular.one(route, productId).get({skipTrips: true});
            });
        }],
        user: user,
        transactions: ['Restangular', function (Restangular) {
          return user.shouldSeeFullAdmin ? Restangular.one('v2/bookings', bookingId).all('transactions').getList() : [];
        }],
        supplierPayments: ['Restangular', function(Restangular) {
          return Restangular.one('v2/bookings', bookingId).all('supplier-payments').getList();
        }],
        paypalRequests: ['Restangular', function(Restangular) {
          return user.shouldSeeFullAdmin ? Restangular.one('v2/bookings', bookingId).all('paypal-requests').getList() : [];
        }]
      },
      windowClass: 'booking-view modal-slide-in-right',
      backdrop: true,
      animation: false
    })
      .result
      .then(function () {
        var search = $location.search();
        delete search.booking;
        $location.search(search);

      }, function (reason) {
        if (reason === 'edit') {
          $state.go('root.editBooking', {bookingId: bookingId});
        } else {
          var search = $location.search();
          delete search.booking;
          $location.search(search);
        }
      });
  };

}

/**
 * Created by john on 27/10/16.
 */
'use strict';

/* exported
selectRouteDirective
 */

function selectRouteDirective() {
  return {
    restrict: 'AE',
    require: ['ngModel', '^form'],
    templateUrl: 'views/bookings/select-route.directive.html',
    scope: {
      name: '@'
    },
    link: function(scope, iElement, iAttrs, ctrls) {
      var ngModelCtrl = ctrls[0];
      scope.parentForm = ctrls[1];

      if (scope.name && scope.parentForm) {
        scope.$watch(function() {
          return scope.parentForm[scope.name].$touched;
        }, function(isTouched) {
          if (isTouched) {
            scope.parentForm.from.$setTouched();
            scope.parentForm.to.$setTouched();
          }
        });
      }


      // From Model to View (Reverse)
      ngModelCtrl.$formatters.push(function(modelValue) {
        return modelValue || {};
      });

      ngModelCtrl.$render = function() {
        scope.from = ngModelCtrl.$viewValue.from || {};

        scope.to = ngModelCtrl.$viewValue.to || {};

      };

      // From View to model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue || {};
      });


      scope.$watch('from', function(from) {
        if (from !== ngModelCtrl.$viewValue.from) {
          ngModelCtrl.$setViewValue({from: from, to: {}});
          scope.to = {};
        }
      });

      scope.$watch('to', function(to) {
        if (to !== ngModelCtrl.$viewValue.to) {
          var viewValue = ngModelCtrl.$viewValue;

          viewValue.to = to;
          ngModelCtrl.$setViewValue(viewValue);
        }
      });
    }
  };
}

'use strict';

/* exported
selectProductDirective
 */

function selectProductDirective(CurrenciesService, SearchRestangular, ProductFormatterService) {
  return {
    restrict: 'AE',
    templateUrl: 'views/bookings/select-product.directive.html',
    scope: {
      productType: '@',
      onProductSelected: '&',
      basicCurrency: '@'
    },
    link: function (scope) {
      scope.filter = {};
      scope.today = new Date();

      scope.$watch('filter.date', () => {
        scope.formattedDate = moment(scope.filter.date).format('YYYY-MM-DD');
      });

      scope.$watch('filter.from._id + filter.to._id + filter.date + productType + filter.company._id', function() {
        if (!scope.filter.from || !scope.filter.to || !scope.filter.date) {
          return;
        }
        SearchRestangular.all('v1/routes/transfers/')
        .getList({ from: scope.filter.from._id, to: scope.filter.to._id, date: scope.formattedDate })
        .then(function (products) {
          scope.products = products.map(function (product) {
            if (_.get(product, 'legs.0.productType') === 'online') {
              return ProductFormatterService.formatOnlineProduct(product);
            } else {
              return ProductFormatterService.formatProduct(product, scope.filter.from._id, scope.filter.to._id);
            }
          })
          if (scope.filter.company) {
            scope.products = scope.products.filter(function (product) {
              const companyId = _.get(product, 'company._id');
              return companyId === scope.filter.company._id;
            });
          }
        });
      });

      scope.displayProduct = function(product) {
        const fromCity = _.get(product, 'from.city.name');
        const toCity = _.get(product, 'to.city.name');
        const denomination = _.get(product, 'denomination');

        var amountInEur;
        var amountInGbp;
        var amountInIls;
        var amount;
        var fromStation;
        var toStation;

        if (product.productType === 'online') {
          amountInEur = scope.basicCurrency === 'EUR' ? CurrenciesService.convertToCurrency(product.price.amount || product.price, 'USD', 'EUR').toFixed(2) + 'EUR' : '';
          amount = `(${product.price.amount || product.price}${product.price.currency || product.currency})`;
          fromStation = _.get(product, 'from.name');
          toStation = _.get(product, 'to.name');
        } else {
          amountInEur = scope.basicCurrency === 'EUR' ? CurrenciesService.convertToCurrency(product.defaultPrice.amount, 'USD', 'EUR').toFixed(2) + 'EUR' : '';
          amountInGbp = scope.basicCurrency === 'GBP' ? CurrenciesService.convertToCurrency(product.defaultPrice.amount, 'USD', 'GBP').toFixed(2) + 'GBP' : '';
          amountInIls = scope.basicCurrency === 'ILS' ? CurrenciesService.convertToCurrency(product.defaultPrice.amount, 'USD', 'ILS').toFixed(2) + 'ILS' : '';
          amount = scope.basicCurrency !== 'USD' ? `(${product.defaultPrice.amount}${product.defaultPrice.currency})` : `${product.defaultPrice.amount}${product.defaultPrice.currency}`;
        }
        return `${product.companyName}: ${fromCity} to ${toCity} - ${denomination} ${product.productType === 'online' ? `(${product.supplier.name} - online)`: ''} ${amountInEur} ${amountInGbp} ${amountInIls} ${amount} ${fromStation && toStation ? `${fromStation} - ${toStation}` :''}`;
      };

      scope.select = function(product) {
        scope.onProductSelected({ product, date: scope.formattedDate });
      };

      scope.getProductUrl = function(product) {
        const isOnline = product.productType === 'online';
        return `/${isOnline ? 'transports' : 'lines'}/edit/${isOnline ? product.transportReference: product._id}`;
      }
    }
  };
}

'use strict';

/* exported
bookingInfoLabelsDirective
 */

function bookingInfoLabelsDirective(APPROVAL_METHOD) {
  return {
    restrict: 'AE',
    templateUrl: 'views/bookings/booking-info-labels.directive.html',
    scope: {
      user: '=user',
      booking: '=booking'
    },
    link: function (scope) {
      scope.formatApproveMethod = function(approveMethod){
        return APPROVAL_METHOD[approveMethod] || approveMethod;
      };
      scope.isAmendRequestActive = function (booking){
        return booking.status === 'pending' && booking.amendStatus === 'pending';
      };
      scope.isBookingPaid = function (booking){
        return ['paid', 'refund', 'authorized'].includes(booking.payment.status);
      }
      scope.isBookingFraudulent = function (booking) {
        return booking.isFraudulent;
      }
    }
  };
}

'use strict';

/* exported
bookingsService
 */

function bookingsService(appConfig, $http, $window, Restangular) {
  var baseUrl = appConfig.bookingsServiceBaseUrl;
  /* jshint validthis: true */
  this.getAll = function(queryString) {
    return $http({
        method: 'GET',
        url: baseUrl + '/bookings',
        params: queryString
      })
      .then(function(bookings) {
        console.log('bookings', bookings);

        return bookings;
      });
  };

  this.getPassengerList = function (company, selectedDate, customerStatus, calculationBasedOn, excludeCanceled) {
    const selectedDay = moment(selectedDate).format('YYYY-MM-DD');
    var selectedDayStartWithTZ = moment.utc(selectedDay).startOf('day').toISOString();
    var selectedDayEndWithTZ = moment.utc(selectedDay).endOf('day').toISOString();
    var queryString =  { limit: 0 };
    if (company && company.isSupplier === false) {
      queryString = angular.merge(queryString, { operator: company._id });
    } else {
       queryString = angular.merge(queryString, { supplier: company._id });
    }
    var reportBy = '';

    if (customerStatus === 'canceled'){
      queryString = angular.merge(queryString, {
        cancellationDateFromExplicit: selectedDayStartWithTZ,
        cancellationDateToExplicit: selectedDayEndWithTZ,
        customerStatus: customerStatus,
      });
      reportBy = 'cd';
    } else {
        if (calculationBasedOn === 'booking_date') {
          queryString = angular.merge(queryString, {
            approvalDateFromExplicit: selectedDayStartWithTZ,
            approvalDateToExplicit: selectedDayEndWithTZ,
            payment: 'paid;refund'
          });
          reportBy = 'bd';
        } else if (customerStatus === 'approved' && calculationBasedOn === 'departure_date') {
          queryString = angular.merge(queryString, { departureDay: selectedDay, customerStatus });
          reportBy = 'dd';
        }
    }
    if (excludeCanceled) {
      queryString = angular.merge(queryString, { excludedStatus: 'canceled' });
    }

    return Restangular
      .one('bookings')
      .get(queryString, {'accept': 'text/csv'})
      .then(function(response) {
        var file = new Blob([response], { type: 'text/csv;charset=utf-8' });
        $window.saveAs(file, 'passengerList-' + company.name +'-' + selectedDay + '-' + reportBy + '-' + '' + '.csv');
      })
      .catch(function(err) {
        throw err;
      });
  };
}

'use strict';

/* exported
voucherResendCtrl
 */

function voucherResendCtrl($scope, booking, $uibModalInstance) {
  $scope.booking = booking;

  $scope.resendVoucher = function() {
    if ($scope.resendVoucherForm.$invalid) {
      $scope.resendVoucherForm.email.$setTouched();
      return;
    }

    $scope.booking
      .post('send-voucher', {email: $scope.email})
      .then(function() {
        $scope.$emit('notify', {type: 'success', title: 'Send Voucher', message: 'Voucher sent with success'});

        $uibModalInstance.close(booking);
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Send Voucher', message: 'Send Voucher failure. <br>' + err.data.message});
      });

  };

  $scope.close = function () {
    $uibModalInstance.close();
  };

}

'use strict';

/* exported
resendBookingRequestCtrl
 */

function resendBookingRequestCtrl($scope, viewedCompany, booking, $uibModalInstance) {
  $scope.booking = booking;
  $scope.emails = viewedCompany.agents.map(function(agent) {
    return agent.email;
  });

  $scope.close = function () {
    $uibModalInstance.close();
  };


  $scope.sendBookingRequest = function() {

    $scope.booking
      .post('notify-supplier', {bookingId: booking._id})
      .then(function() {
        $scope.$emit('notify', {type: 'success', title: 'Notify Supplier', message: 'Supplier notified with success'});

        $uibModalInstance.close();
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Notify Supplier', message: 'Supplier notify failure. <br>' + err.data.message});
      });

  };
}

'use strict';

/* exported
getPassengerListCtrl
 */

function getPassengerListCtrl($scope, $uibModalInstance) {
  $scope.selectedDate = moment().subtract(1, 'day').toDate(); // default
  $scope.calculationBasedOn = 'departure_date';

  $scope.confirmDownloadPassengerListModel = function () {
    const params = {
      selectedDate: $scope.selectedDate,
      calculationBasedOn: $scope.calculationBasedOn
    };
    $uibModalInstance.close(params);
  };

  $scope.close = function () {
    $uibModalInstance.close(false);
  };
}

'use strict';

/* exported
bulkVouchersSendCtrl
 */

function bulkVouchersSendCtrl($scope, bookings, filters, $uibModalInstance, Restangular, $uibModal) {
  $scope.bookings = bookings;
  $scope.filters = filters;
  $scope.bookingToSend = bookings.filter(function (booking){
    return booking.customerStatus === 'approved';
  });

  $scope.bulkSendVouchers = function() {

    if ($scope.bookingToSend.length === 0){
      $scope.$emit('notify', {type: 'error', title: 'Error', message: 'No Approved Bookings to send'});
      return;
    }

    const bookingReferencesArray = $scope.bookingToSend.map(function (booking){
      return booking.reference;
    });

    $uibModal
      .open({
        templateUrl: 'views/are-you-sure-modal.html',
        controller: 'AreYouSureCtrl',
        resolve: {
          text: null,
          title: [function () { return 'Are you sure?'; }]
        }
      })
      .result
      .then(function(response) {
        if (response){
          Restangular
            .all('v2/bookings/bulk-send-vouchers')
            .post({bookingReferencesArray: bookingReferencesArray})
            .then(function(response) {
              $scope.$emit('notify', {type: 'success', title: 'Bulk Send Vouchers', message: 'Bulk vouchers sent with success <br> ' + response.message});
              $uibModalInstance.close();
            })
            .catch(function(err) {
              $scope.$emit('notify', {type: 'error', title: 'Bulk Send Vouchers', message: 'Bulk vouchers failed. <br>' + err.data.message});
              $uibModalInstance.close();
            });
        }
      });



  };

  $scope.close = function () {
    $uibModalInstance.close();
  };
}

'use strict';

/* exported
bulkEmailSendCtrl
 */

function bulkEmailSendCtrl($scope, bookings, filters, $uibModalInstance, Restangular, $uibModal) {
  $scope.bookings = bookings;
  $scope.filters = filters;
  $scope.bookingToSend = bookings;

  $scope.bulkSendEmail = function () {

    if ($scope.bookingToSend.length === 0) {
      $scope.$emit('notify', { type: 'error', title: 'Error', message: 'No Approved Bookings to send' });
      return;
    }

    if (!$scope.subject || !$scope.message) {
      $scope.$emit('notify', { type: 'error', title: 'Error', message: 'fields can\'t be empty' });
      return;
    }

    const bookingReferencesArray = $scope.bookingToSend.map(function (booking) {
      return booking.reference;
    });

    $uibModal
      .open({
        templateUrl: 'views/are-you-sure-modal.html',
        controller: 'AreYouSureCtrl',
        resolve: {
          text: null,
          title: [function () { return 'Are you sure?'; }]
        }
      })
      .result
      .then(function (response) {
        if (response) {
          Restangular
            .all('v2/bookings/bulk-send-email')
            .post({ bookingReferencesArray: bookingReferencesArray, message: $scope.message, subject: $scope.subject, fromEmail: 'support@bookaway.com' })
            .then(function (response) {
              $scope.$emit('notify', {
                type: 'success',
                title: 'Bulk Send Email',
                message: 'Bulk email sent with success <br> ' + response.message
              });

              $uibModalInstance.close();
            })
            .catch(function (err) {
              $scope.$emit('notify', {
                type: 'error',
                title: 'Bulk Send Email',
                message: 'Bulk email failed. <br>' + err.data.message
              });
              $uibModalInstance.close();
            });
        }
      });
  };

  $scope.close = function () {
    $uibModalInstance.close();
  };
}

/**
 * Created by john on 7/1/17.
 */
'use strict';

/* exported
 viewBookingCtrl
 */

function viewBookingCtrl($scope, booking, notes, $uibModalInstance, $uibModal, appConfig, moment, user, ImagesRestangular, Restangular, transactions,paypalRequests, supplierPayments, declineSrv, product, BookingViewModal, $state, $location, CurrenciesService, PRODUCT_TYPES) {
  var types = {
    'dropoff_hotel': 'Hotel Drop Off',
    'dropoff_airport': 'Airport Drop Off',
    'pickup_airport': 'Airport Pick Up',
    'pickup_hotel': 'Hotel Pick Up',
    'pickup_point': 'Pick Up Point',
    'dropoff_point': 'Drop Off Point',
    'upgrade': 'Upgrade'
  };

  var flexibleScheduleMap = {
    'notFlexible': 'Not flexible',
    'flexibleNext': 'Flexible within the next 3 hours',
    'flexiblePrevious': 'Flexible within the previous 3 hours',
    'flexibleBoth': 'Super flexible 3 hours before or after',
  };

  $scope.isNotUsd = booking.basicCurrency !== 'USD';
  $scope.userEventsPermissions = user.scope.includes('view-user-events')
  $scope.currencySign = CurrenciesService.getCurrencySign(booking.basicCurrency);
  $scope.productTypes = PRODUCT_TYPES;
  $scope.appConfig = appConfig;
  $scope.displayPriceForSupplier = (booking.price.costInSupplierCurrency).toFixed(2);
  $scope.displayPriceForSupplier = booking.payment.status === 'refund' ?
    (booking.price.costInSupplierCurrency).toFixed(2) :
    (booking.priceWithoutAddons.costInSupplierCurrency).toFixed(2);
  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  function getProductType(booking) {
    return booking.items[0].productType;
  }

  // Is new or old options
  $scope.isNewExtras = false;
  $scope.mandrillEmails = null;

  var bookingExtras = booking.items.reduce(function(acc, current) {
    return acc.concat(current.extraOptions);
  }, booking.extraOptions);

  var oldExtras = product.extraOptionDefinitions;

  if (!
    (bookingExtras &&
      bookingExtras.length > 0 &&
      oldExtras &&
      oldExtras.length &&
      oldExtras.find(function(extra) {
        return extra._id === bookingExtras[0].definition;
      })
    )
  ) {
    if ((product.extras && product.extras.length) || !product.extraOptionDefinitions || product.extraOptionDefinitions.length === 0) {
      $scope.isNewExtras = true;
    }
  }

  $scope.booking = booking;

  function modifyItems(items, booking, direction) {
    if (!booking || !booking.items || booking.items.length === 0) return [];

    return items.map(function(item) {
        var fromCity = booking.items[0].trip.fromId.city.name;
        var toCity = booking.items[0].trip.toId.city.name;
        var modifiedDirection = direction === 1 ? `${fromCity} to ${toCity}` : `${toCity} to ${fromCity}`;

        return Object.assign({}, item, { direction: modifiedDirection });
    });
  }

  function getTransferData(bookingItems, index, dataType) {
    var item = bookingItems[index];
    if (!item || !item.transferData) return [];

    var isRefundable = item.transferData.isRefundable;
    var isAmendable = item.transferData.isAmendable;

    if ((dataType === 'cancellations' && !isRefundable) ||
        (dataType === 'amendments' && !isAmendable)) {
        return [];
    }

    return item.transferData[dataType] || [];
  }

  function processItems(booking, itemType, modificationDirection) {
    var item1 = getTransferData(booking.items, 0, itemType);
    var item2 = getTransferData(booking.items, 1, itemType);

    var modifiedItem1 = modifyItems(item1, booking, modificationDirection);
    var modifiedItem2 = modifyItems(item2, booking, modificationDirection + 1);

    return modifiedItem1.concat(modifiedItem2);
  }

  $scope.cancellations = processItems(booking, 'cancellations', 1);
  $scope.amendments = processItems(booking, 'amendments', 1);

  $scope.notes = notes;
  var totalCharged = 0;
  $scope.transactions = transactions.map(function (transaction) {
    var supplierPayment = supplierPayments.find(function(sp) {
      return sp.transactionId === transaction.id;
    });

    if (user && user.shouldSeeFullAdmin) {
      transaction.supplierAmount = (supplierPayment || {}).amount || 0;
      totalCharged = ['charge', 'authorize'].includes(transaction.type) ? totalCharged + transaction.amount : totalCharged - transaction.amount;
      return transaction;
    }

    return supplierPayment;
  });
  $scope.allTransactionsAmount = totalCharged;
  $scope.paypalRequests = paypalRequests || [];
  $scope.user = user;

  if (user &&  user.shouldSeeFullAdmin) {
    Restangular.one('v2/bookings', booking.reference).all('mandrill-emails-data').getList()
        .then(function (mandrillEmailsResponse) {
          $scope.mandrillEmails = mandrillEmailsResponse;
        })
        .catch(function (err) {
          console.error('error while fetching mandrillEmails', err.message);
        });
  }

  if (user &&  user.shouldSeeFullAdmin) {
    Restangular.one('v2/bookings', booking._id).all('booking-history').getList({reference: booking.reference})
      .then(function (bookingHistoryResponse) {
        $scope.bookingHistory = bookingHistoryResponse;
      })
      .catch(function (err) {
        console.error('error while fetching booking-history', err.message);
      });
  } else {
    $scope.bookingHistory = [];
  }

  if (user.shouldSeeFullAdmin) {
    Restangular.one('v1/carts', booking.cartReference).get()
      .then(function (cartResponse) {
        $scope.cart = cartResponse;
      })
      .catch(function (err) {
        console.error('error while fetching cart', err.message);
      });
  }

  if (booking.departureTimeAvailability) {
    $scope.departureTimeAvailabilityLabel = flexibleScheduleMap[booking.departureTimeAvailability];
  }

  $scope.getExtraOptionDefinition = function(extraOption, item) {
    var extraOptionDefinitions = product.extraOptionDefinitions;
    if (product.extras && product.extras.length && $scope.isNewExtras) {
      extraOptionDefinitions = product.extras;
    }

    const extra = extraOptionDefinitions.find(function(extraOptionDefinition) {
      return extraOptionDefinition._id === extraOption.definition;
    });
    if (!item) return extra;

    let stationExtra;
    if (item && item.transferData && item.transferData.stationExtras) {
      stationExtra = item.transferData.stationExtras.find((e) => e.id === extraOption.definition);
    }
    return stationExtra || extra
  };

  $scope.getInsurancePolicies = function () {
    const insuranceAddon = (booking.addons || []).find((addon) => addon.slug === 'insurance');
    if (insuranceAddon && insuranceAddon.apiResponse && insuranceAddon.apiResponse.policyId) {
      return insuranceAddon.apiResponse.policyId;
    } else if (insuranceAddon.apiResponse && insuranceAddon.apiResponse.policies) {
      return insuranceAddon.apiResponse.policies.map((policy) => policy.policyId).join();
    }
    return '';
  }

  $scope.getInsurancePolicyForPassenger = function (passengerName) {
    const insuranceAddon = (booking.addons || []).find((addon) => addon.slug === 'insurance');
    if (insuranceAddon && insuranceAddon.apiResponse && insuranceAddon.apiResponse.policyId) {
      return insuranceAddon.apiResponse.policyId;
    } else if (insuranceAddon && insuranceAddon.apiResponse.policies) {
      const policy = insuranceAddon.apiResponse.policies.find((item) => item.name === passengerName);
      return policy ? policy.policyId : null;
    }

    return '';
  }

  $scope.getExtraLabel = function(extraOption, item) {
    var definition = $scope.getExtraOptionDefinition(extraOption, item);

    if (!definition) {
      return 'Deleted extra (' + extraOption.value + ')';
    }

    if (!definition.extraType) {
      return definition.label; // for old extras
    }

    if (definition.extraType === 'upgrade') {
      return definition.upgrade;
    }

    return types[definition.extraType];
  };

  function getApprovedValue(extraLabel, extra, item) {
    switch(extraLabel){
      case types.dropoff_hotel:
      case types.dropoff_airport:
      case types.dropoff_point: {
        return item.approvedArrival ? item.approvedArrival.to : item.trip && item.trip.toId && item.trip.toId.address;
      }
      case types.pickup_hotel: {
        return item.approvedDeparture ? moment.tz(item.approvedDeparture.time, item.trip.departure.timezone).format('HH:mm') + ' - ' + item.approvedDeparture.from : '';
      }
      case types.pickup_airport: {
        return item.approvedDeparture ? moment.tz(item.approvedDeparture.time, item.trip.departure.timezone).format('HH:mm') + ' - ' + extra.value : '';
      }
      case types.pickup_point: {
        return item.approvedDeparture ? item.approvedDeparture.from : '';
      }
      default: {
        return extra.value;
      }
    }
  }

  if (booking.items[0] && booking.items[0].extraOptions && booking.items[0].extraOptions.length) {
    var oneWayItem = booking.items[0];
    $scope.newExtrasOneWayApprovalInfo = [];
    oneWayItem.extraOptions.forEach(function (extra) {
      var fieldName = $scope.getExtraLabel(extra, oneWayItem);
      var value = getApprovedValue(fieldName, extra, oneWayItem);
      $scope.newExtrasOneWayApprovalInfo.push({
        fieldName: fieldName,
        value: value,
      });
      if (extra.comment) {
        $scope.newExtrasOneWayApprovalInfo.push({
          fieldName: fieldName + ' comment',
          value: extra.comment,
        });
      }
    });
  }
  if (booking.items[1] && booking.items[1].extraOptions && booking.items[1].extraOptions.length) {
    var returnWayItem = booking.items[1];
    $scope.newExtrasReturnWayApprovalInfo = [];
    returnWayItem.extraOptions.forEach(function (extra) {
      var fieldName = $scope.getExtraLabel(extra,returnWayItem);
      var value = getApprovedValue(fieldName, extra, returnWayItem);
      $scope.newExtrasReturnWayApprovalInfo.push({
        fieldName: fieldName,
        value: value,
      });
      if (extra.comment) {
        $scope.newExtrasReturnWayApprovalInfo.push({
          fieldName: fieldName + ' comment',
          value: extra.comment,
        });
      }
    });
  }
  if (booking.extraOptions && booking.extraOptions.length) {
    $scope.globalNewExtrasApprovalInfo = [];
    booking.extraOptions.forEach(function (extra) {
      var fieldName = $scope.getExtraLabel(extra);
      $scope.globalNewExtrasApprovalInfo.push({
        fieldName: fieldName,
        value: extra.value,
      });
      if (extra.comment) {
        $scope.globalNewExtrasApprovalInfo.push({
          fieldName: fieldName + ' comment',
          value: extra.comment,
        });
      }
    });
  }
  if (booking.extraInfo) {
    $scope.extraInfo = booking.extraInfo;
  }


  $scope.getThumbnailStyle = function(image) {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + image.url + '?width=300';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };

  // Approval attachement init
  ($scope.booking.items[0].approvalAttachements || []).forEach(function(approvalAttachement) {
    ImagesRestangular.one('files/' + approvalAttachement.id).get()
      .then(function(response) {
        approvalAttachement.mimetype = response.mimetype;
        approvalAttachement.name = response.originalName;
      });
  });

  ($scope.booking.voucherAttachments || []).forEach(function(approvalAttachement) {
    ImagesRestangular.one('files/' + approvalAttachement.id).get()
      .then(function(response) {
        approvalAttachement.mimetype = response.mimetype;
        approvalAttachement.name = response.originalName;
      });
  });

  $scope.getTime = function(duration) {
    var momentDuration = moment.duration(duration);

    return pad(momentDuration.hours(), 2, '0') + ':' + pad(momentDuration.minutes(), 2, '0');
  };

  $scope.cancel = function() {
    if (!user.additionalScopes.includes('cancel_booking')) {
      $scope.$emit('notify', {type: 'error', title: 'Cannot cancel booking', message: 'User does not have the right permissions'});
    } else {
      $uibModal
      .open({
        templateUrl: 'views/bookings/cancel.html',
        controller: 'BookingCancelCtrl',
        resolve: {
          booking: [function() {
            return $scope.booking;
          }],
          transactions: [function() {
            return $scope.transactions;
          }],
          cancellationReasons: [function(){
            return $scope.booking.status === 'declined' ? CHANGE_PRICE_REASONS.DECLINED_BOOKING : CHANGE_PRICE_REASONS.CANCEL_BOOKING;
          }],

        }
      })
      .result
      .then(function(booking) {
        $scope.booking = booking;
      });
    }
  };

  $scope.decline = function() {
    $uibModal
      .open({
        templateUrl: 'views/bookings/decline.html',
        controller: 'BookingDeclineCtrl',
        windowClass: 'decline-modal',
        resolve: {
          booking: [function() {
            return $scope.booking;
          }],
          user: user,
        }
      })
      .result
      .then(function(booking) {
        $scope.booking = booking;
      });
  };

  $scope.viewAmendRequest = function() {
    $uibModal
      .open({
        templateUrl: 'views/bookings/amend-request.html',
        controller: 'BookingAmendRequestCtrl',
        windowClass: 'amend-request-modal',
        resolve: {
          booking: [function() {
            return booking;
          }],
          product: [function() {
            return product;
          }]
        }
      })
      .result
      .then(function() {
      });
  };

  $scope.approve = function(isReapprove) {
    $uibModal
      .open({
        templateUrl: 'views/bookings/approve.html',
        controller: 'BookingApproveCtrl',
        resolve: {
          booking: [function() {
            return $scope.booking;
          }],
          user: user,
          isReapprove: [function() {
            return isReapprove || false;
          }],
          product: [function() {
            return product;
          }]
        }
      })
      .result
      .then(function(booking) {
        $scope.booking = booking;
      });
  };

  $scope.openVoucherModal = function() {
    $uibModal
      .open({
        templateUrl: 'views/bookings/voucher-resend.html',
        controller: 'VoucherResendCtrl',
        resolve: {
          booking: [function() {
            return $scope.booking;
          }]
        }
      })
      .result
      .then(function() {
        console.log('voucher resent');
      });
  };

  $scope.openSupplierModal = function() {
    $uibModal
      .open({
        templateUrl: 'views/bookings/resend-booking-request.html',
        controller: 'ResendBookingRequestCtrl',
        resolve: {
          viewedCompany: ['UsersRestangular', function(UsersRestangular) {
            return UsersRestangular.one('companies', $scope.booking.items[0].supplier._id).get({populate: 'agents'});
          }],
          booking: [function() {
            return $scope.booking;
          }],
        }
      })
      .result
      .then(function() {
        console.log('booking request resent');
      });
  };

  $scope.openSupplierDataModal = function (itemReference) {
    Restangular.one("v2/bookings", booking._id)
      .one("supplier-api")
      .get({ itemReference })
      .then(function (bookingSupplierDataResponse) {
        $uibModal.open({
          templateUrl: "views/bookings/supplier-data.html",
          controller: "BookingSupplierDataCtrl",
          resolve: {
            booking: [
              function () {
                return $scope.booking;
              },
            ],
            bookingSupplierData: [
              function () {
                return bookingSupplierDataResponse;
              },
            ],
          },
        });
      })
      .catch(function (err) {
        console.error("error while fetching booking", err.message);
      });
  };

  $scope.reapprove = function() {
    $scope.approve(true);
  };

  $scope.edit = function() {
    $uibModalInstance
      .dismiss('edit');
  };

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.getFileUrl = function(url) {
    return appConfig.imagesServiceBaseUrl + '/media/files/' + url;
  };

  $scope.canGenerateTicket = function() {
    var oneMinute = moment($scope.booking.updatedAt);
    oneMinute.add(1, 'minutes');

    return $scope.booking.status === 'approved' && !$scope.booking.items[0].ticket && oneMinute.isBefore(moment());
  };

  $scope.generateTicket = function() {
    return $scope.booking
      .post('ticket')
      .then(function(bookingWithTicket) {
        $scope.$emit('notify', {type: 'success', title: 'Ticket', message: 'Ticket is being generating'});
        console.log('return of the ticket', bookingWithTicket);
      })
      .catch(function(error) {
        $scope.$emit('notify', {type: 'error', title: 'Ticket', message: 'Error trying to generate ticket'});
        console.log('error', error);
      });
  };

  $scope.getTrackUrl = function() {
    return appConfig.frontendBrowserUrl + '/track?email=' + $scope.booking.items[0].passengers[0].contact.email +
      '&reference=' + $scope.booking.reference;
  };

  $scope.getPdfUrl = function() {
    return appConfig.frontendBrowserUrl + '/_api/images/' + $scope.booking.misc.pdfFileName;
  };

  $scope.getB2BPdfUrl = function() {
    return appConfig.frontendBrowserUrl + '/_api/images/' + $scope.booking.misc.b2bPdfFileName;
  };

  $scope.getConsumersUrl = function() {
    var items = $scope.booking.items;
    var product = items[0].product;
    var trip = items[0].trip;
    var isOnlineProduct = items[0].productType === PRODUCT_TYPES.ONLINE;

    var fromAtoB = trip.fromId.city.slug + '-to-' + trip.toId.city.slug;
    var fromAtoBWithDenomination = fromAtoB + '/' + product.slug;
    var fromStation = trip.fromId._id;
    var toStation = trip.toId._id;
    var stationsQuery = isOnlineProduct && appConfig.transports ? `fromstation=${fromStation}&tostation=${toStation}` : '';
    return `${appConfig.frontendBrowserUrl}/routes/${trip.toId.city.countrySlug}/${fromAtoBWithDenomination}?${stationsQuery}`;
  };

  $scope.getLineId = function () {
    const product = $scope.booking.items[0].product;
    return product._id || product.slug;
  };

  $scope.getProductType = getProductType;

  $scope.getPassengerExtraInfoDefinition = function(passengerExtraInfo) {
    return product.passengerExtraInfoDefinitions.find(function(passengerExtraInfoDefinition) {
      return passengerExtraInfoDefinition._id === passengerExtraInfo.definition;
    });
  };

  $scope.getPack = function(packId) {
    return product.packs.find(function(pack) {
      return pack._id === packId;
    });
  };

  $scope.changePrice = function() {
    // if ($scope.booking.payment.isDisputed) {
    //   return $scope.$emit('notify', {type: 'error', title: 'Ticket', message: 'Cannot change Price for bookings that are under dispute.'});
    // }
    $uibModal
      .open({
        templateUrl: 'views/payments/change-price.html',
        controller: 'PaymentChangePriceCtrl',
        resolve: {
          booking: [function() {
            return $scope.booking;
          }],
          oldBooking: [function(){
            return undefined;
          }],
          changePriceReasons: [function(){
            return CHANGE_PRICE_REASONS.CHANGE_PRICE;
          }],
          user: user,
          paymentCycles: ['Restangular', function(Restangular) {
            $scope.supplierPaymentCycles;
            const doFetchCycles = $scope.booking.items[0].supplier.paymentType === 'invoice';

            if (doFetchCycles) {
              return Restangular.one('v2/supplier')
              .all(`${$scope.booking.items[0].supplier._id}/cycles-for-reconciliation?allowPastCycles=false`).getList({ status: { include: ['open', 'invoice_rejected', 'invoice_received'] }})
              .then(function(cycles) {
                $scope.supplierPaymentCycles = cycles.map((cycle) => {
                  let period = moment(cycle.startDate).format('MMMM YYYY');
                  if (cycle.invoicePaymentCycle === 'bi_weekly') {
                    period = `${moment(cycle.startDate).date()}-${moment(cycle.endDate).date()} ${moment(cycle.startDate).format('MMM')} ${moment(cycle.endDate).format('YYYY')}`;
                  }
                  return {
                    start: cycle.startDate,
                    end: cycle.endDate,
                    label: period,
                    currency: cycle.currency,
                  }
                })
                return $scope.supplierPaymentCycles;
              })
            }
            return $scope.supplierPaymentCycles;
          }],
        },
        windowClass: 'change-price-modal',
      })
      .result
      .then(function(booking) {
        $scope.booking = booking;
      });
  };

  $scope.getBookingTitle = function(booking) {
    if (['line', 'online'].includes(booking.items[0].productType)) {
      var title = booking.items[0].trip.fromId.city.name + ' to ' + booking.items[0].trip.toId.city.name;

      if (booking.items.length === 2) {
        title += ' and return';
      }

      return title;
    } else {
      return booking.items[0].product.denomination + ' | from ' + booking.items[0].trip.fromId.city.name;
    }
  };

  $scope.declineReason = function() {
    return declineSrv.formatReason($scope.booking.declineReason, $scope.booking.declineReasonText);
  };

  $scope.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  $scope.noteObject = {};
  $scope.addNote = function() {
    Restangular.all('notes').post({booking: booking._id, note: $scope.noteObject.newNote})
      .then(function(note){
        $scope.$emit('notify', {type: 'success', title: 'Add note', message: 'note added with Success'});
        $scope.notes.unshift(note);
        $scope.noteObject = {};
    }).catch(function(err) {
      $scope.$emit('notify', {type: 'error', title: 'Add note', message: 'Failed to add note'});

      $scope.globalError = err.data.message;
    });
  };

  $scope.showBooking = function(booking) {
    BookingViewModal.show(booking._id, user);

    $uibModalInstance.close();

    //delay for 1 tick ($uibModalInstance.close() - removes the bookingId from the search....)
    setTimeout(function (){
      var search = $location.search();
      search.booking = booking._id;
      $location.search(search);
    }, 1);
  };

  $scope.filterCart = function (cartReference){
    $state.go($state.current, {cartReference: cartReference }, {reload: true});
    $uibModalInstance.close();
  };

  function calcTimeDiffToDeparture(date, timezone) {
    if (moment.tz(date, timezone).isBefore(moment())) {
      return {
        text: 'Departure passed',
        isFuture: false
      };
    }
    const diffMoment = moment.duration(moment.tz(date, timezone).diff(moment()));
    const years = Math.floor(diffMoment.years());
    const months = Math.floor(diffMoment.months());
    const days = Math.floor(diffMoment.days());
    const hours = Math.floor(diffMoment.hours());
    const minutes = Math.floor(diffMoment.minutes());
    let str = '';
    if (years > 0){
      str += years + ' years ';
    }
    if (months > 0){
      str += months + ' months ';
    }
    if (days > 0){
      str += days + ' days ';
    }
    if (hours > 0){
      str += hours + ' hours ';
    }
    if(minutes > 0){
      str += minutes + ' minutes';
    }
    return {

      text: 'Time to departure: ' + str,
      isFuture: true
    };
  }

  $scope.departureTimeDiff = calcTimeDiffToDeparture(booking.items[0].trip.departure.date, booking.items[0].trip.departure.timezone);
  if (booking.items[1]) {
    $scope.returnTimeDiff = calcTimeDiffToDeparture(booking.items[1].trip.departure.date, booking.items[1].trip.departure.timezone);
  }

  $scope.getStatusStyle = function(status) {
    if (status === 'paid' || status === 'reserved') {
        return {'background-color': '#D1FAD3', 'color': '#166219'};
    } else if (status === 'pending' || status === 'inprogress') {
      return {'background-color': '#FFDAC8', 'color': '#662101'};
    }else if (status === 'cancelled' || status === 'failed') {
        return {'background-color': '#FCD5D7', 'color': '#E70B16'};
    }

    return {'background-color': '#000000', 'color': '#FFFFFF'};
  };

  $scope.isCartTransactionsCollapsed = true;
  $scope.toggleCartTransactionsCollapse = function() {
    $scope.isCartTransactionsCollapsed = !$scope.isCartTransactionsCollapsed;
  };
}

'use strict';

/* exported
menuBookingsCtrl
 */

function menuBookingsCtrl($scope, fetchDomains, $location, $rootScope, moment, user, countries) {
  const paymentStatuses = ['paid', 'refund','authorized', 'unpaid', 'failed', 'expired'];

  const defaultFilters = {
    domain: 'all',
    sort: 'bookedAt:-1',
    date: 'created',
    payment: 'paid;authorized',
    status: '',
    amendStatus: '',
    region: '',
  };

  $scope.approveMethods = {
    'manual-approve': 'Regular',
    'auto-approve-flag': 'Auto Approve',
    'auto-approve-allocation': 'Prepaid',
    'approved-by-supplier-api': 'Approved by Supplier Api'
  };

  $scope.filters = Object.assign({}, defaultFilters);

  $scope.paymentStatusModel = [{id: 0}, {id: 2}];
  $scope.transclusionSettings = {};
  $scope.paymentStatusData = [
    {
      id: 0,
      label: 'Paid',
    }, {
      id: 1,
      label: 'Refund',
    }, {
      id: 2,
      label: 'Authorized',
    },
  ];

  if (user.shouldSeeFullAdmin) {
    $scope.paymentStatusData.push({id: 3, label: 'Unpaid'});
    $scope.paymentStatusData.push({id: 4, label: 'Failed'});
  }

  $scope.paymentStatusSettings = {};

  $scope.paymentStatusSelectionEventHandler = {
    onItemSelect(item) {
      const selectedPaymentStatuses = [paymentStatuses[item.id]];
      if($scope.filters.payment !== ''){
        selectedPaymentStatuses.push(...$scope.filters.payment.split(';'));
      }
      $scope.filters.payment = selectedPaymentStatuses.join(';');
    },
    onItemDeselect(item) {
      $scope.filters.payment = $scope.filters.payment.split(';')
        .filter(paymentStatus => paymentStatuses[item.id] !== paymentStatus)
        .join(';');
    },
    onSelectAll() {
      if(user.shouldSeeFullAdmin){
        $scope.filters.payment = paymentStatuses.join(';');
      } else {
        $scope.filters.payment = ['paid', 'refund','authorized'].join(';');
      }
    },
    onDeselectAll() {
      $scope.filters.payment = '';
    },
  };

  $scope.user = user;

  $scope.countries = countries.map(function (country) {
    return country.name.common;
  }).sort();

  $scope.capitalize = _.capitalize;
  $scope.domains = [
    'all',
    'bookaway'
  ];
  fetchDomains().then(function (domains) {
    $scope.domains = domains;
    $scope.$apply();
  });

  $scope.regions = ['emea', 'apac', 'latam', 'north_america'];

  $scope.clearCountry = function clearCountry() {
    delete $scope.filters.country;
  };

  $scope.clearApproveMethod = function clearApproveMethod() {
    delete $scope.filters.approveMethod;
  };

  // On init if type is selected display it
  var search = $location.search();

  if (search.type) {
    $scope.filters.type = search.type;
  }

  // On init if status is selected display it
  if (search.status) {
    $scope.filters.status = search.status;
    $rootScope.$broadcast('filters.status', $scope.filters.status);
  }

  if (search.manual) {
    $scope.filters.manual = search.manual;
  }

  if (search.isHandled) {
    $scope.filters.isHandled = search.isHandled;
  }

  if (search.amendStatus) {
    $scope.filters.amendStatus = search.amendStatus;
  }

  if (search.payment) {
    $scope.filters.payment = search.payment;
  }

  // On init if reference is selected display it
  if (search.references) {
    $scope.filters.references = search.references;
  }

  if (search.extraInfo) {
    $scope.filters.extraInfo = search.extraInfo;
  }

  if (search.supplierBookingCode) {
    $scope.filters.supplierBookingCode = search.supplierBookingCode;
  }

  // On init if email is selected display it
  if (search.email) {
    $scope.filters.email = search.email;
  }

  if (search.createdFromExplicit) {
    $scope.filters.createdFromExplicit = new Date(search.createdFromExplicit);
  }

  if (search.createdToExplicit) {
    $scope.filters.createdToExplicit = new Date(search.createdToExplicit);
  }

  if (search.departureFrom) {
    $scope.filters.departureFrom = new Date(search.departureFrom);
  }

  if (search.departureTo) {
    $scope.filters.departureTo = new Date(search.departureTo);
  }

  // On init if company is selected display it
  if (search.company) {
    $scope.filters.company = {_id: search.company};
  }

  if (search.supplier) {
    $scope.filters.supplier = {_id: search.supplier};
  }

  // On init if departure is selected display it
  if (search.departure) {
    $scope.filters.departure = {_id: search.departure};
  }

  // On init if arrival is selected display it
  if (search.arrival) {
    $scope.filters.arrival = {_id: search.arrival};
  }

  if (search.region) {
    $scope.filters.region = search.region;
  }

  if (search.country) {
    $scope.filters.country = search.country;
  }

  if (search.sort) {
    $scope.filters.sort = search.sort;
  }

  if (search.ApproveMethod){
    $scope.filters.approveMethod = search.approveMethod;
  }

  if (search.tripId){
    $scope.filters.tripId = search.tripId;
  }

  if (search.eventId) {
    $scope.filters.eventId = search.eventId;
  }

  if (search.productId) {
    $scope.filters.productId = search.productId;
  }

  if (search.cartReference) {
    $scope.filters.cartReference = search.cartReference;
  }

  if (search.firstName) {
    $scope.filters.firstName = search.firstName;
  }

  if (search.lastName) {
    $scope.filters.lastName = search.lastName;
  }

  if (search.customerStatus) {
    $scope.filters.customerStatus = search.customerStatus;
  }

  $scope.filterByDomain = function() {
    $rootScope.$broadcast('filters.domain', $scope.filters.domain);

    var search = $location.search();
    search.domain = $scope.filters.domain;
    $location.search(search);
  };

  $scope.filterByManual = function (status) {
    if ($scope.filters.manual === status) {
      $scope.filters.manual = null;
    } else {
      $scope.filters.manual = status;
    }

    $rootScope.$broadcast('filters.manual', $scope.filters.manual);

    var search = $location.search();
    search.manual = $scope.filters.manual;
    $location.search(search);
  };

  $scope.toggleFilterByAmendStatus = function() {
    $scope.filters.amendStatus = $scope.filters.amendStatus ? null : 'pending';

    $rootScope.$broadcast('filters.amendStatus', $scope.filters.amendStatus);

    var search = $location.search();
    search.amendStatus = $scope.filters.amendStatus;
    $location.search(search);
  };

  $scope.filterByStatus = function(status) {
    if ($scope.filters.status === status) {
      $scope.filters.status = null;
    } else {
      $scope.filters.status = status;
    }

    $rootScope.$broadcast('filters.status', $scope.filters.status);

    var search = $location.search();
    search.status = $scope.filters.status;
    $location.search(search);
  };

  $scope.filterByCustomerStatus = function(status) {
    if ($scope.filters.customerStatus === status) {
      $scope.filters.customerStatus = null;
    } else {
      $scope.filters.customerStatus = status;
    }

    $rootScope.$broadcast('filters.customerStatus', $scope.filters.customerStatus);

    var search = $location.search();
    search.customerStatus = $scope.filters.customerStatus;
    $location.search(search);
  };

  $scope.$watch('filters.references', function (references, previousValue) {

    if (references === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.references', references);

    var search = $location.search();
    search.references = references;
    $location.search(search);
  });

  $scope.$watch('filters.extraInfo', function (extraInfo, previousValue) {

    if (extraInfo === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.extraInfo', extraInfo);

    var search = $location.search();
    search.extraInfo = extraInfo;
    $location.search(search);
  });

  $scope.$watch('filters.supplierBookingCode', function (bookingCode, previousValue) {
    if( bookingCode === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.supplierBookingCode', bookingCode);

    var search = $location.search();
    search.supplierBookingCode = bookingCode;
    $location.search(search);
  })

  $scope.$watch('filters.email', function (email, previousValue) {
    if (email === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.email', email);

    var search = $location.search();
    search.email = email;
    $location.search(search);
  });

  $scope.$watch('filters.region', function (region, previousValue) {
    if (region === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.region', region);

    var search = $location.search();
    search.region = region;
    $location.search(search);
  });

  $scope.$watch('filters.firstName', function (firstName, previousValue) {
    if (firstName === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.firstName', firstName);

    var search = $location.search();
    search.firstName = firstName;
    $location.search(search);
  });

  $scope.$watch('filters.lastName', function (lastName, previousValue) {
    if (lastName === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.lastName', lastName);

    var search = $location.search();
    search.lastName = lastName;
    $location.search(search);
  });

  $scope.$watch('filters.company', function (company, previousValue) {
    if (company === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.company', (company || {})._id);

    var search = $location.search();
    search.company = (company || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.supplier', function (supplier, previousValue) {
    if (supplier === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.supplier', (supplier || {})._id);

    var search = $location.search();
    search.supplier = (supplier || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.departure', function (departure, previousValue) {
    if (departure === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.departure', (departure || {})._id);

    var search = $location.search();
    search.departure = (departure || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.arrival', function (arrival, previousValue) {
    if (arrival === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.arrival', (arrival || {})._id);

    var search = $location.search();
    search.arrival = (arrival || {})._id;
    $location.search(search);
  });

  $scope.$watch('filters.createdFromExplicit', function (from, previousValue) {
    if (from === previousValue) {
      return;
    }
    const m = moment(from);
    var data = from ? moment.utc(m.startOf('day')).format() : from;
    $rootScope.$broadcast('filters.createdFromExplicit', data);

    var search = $location.search();
    search.createdFromExplicit = data;
    $location.search(search);
  });

  $scope.$watch('filters.createdToExplicit', function (to, previousValue) {
    if (to === previousValue) {
      return;
    }
    const m = moment(to);
    var data = to ? moment.utc(m.endOf('day')).format() : to;
    $rootScope.$broadcast('filters.createdToExplicit', data);

    var search = $location.search();
    search.createdToExplicit = data;
    $location.search(search);
  });

  $scope.$watch('filters.departureFrom', function (departure, previousValue) {
    if (departure === previousValue) {
      return;
    }

    var data = departure ? moment(departure).format('YYYY-MM-DD') : departure;

    $rootScope.$broadcast('filters.departureFrom', data);

    var search = $location.search();
    search.departureFrom = data;
    $location.search(search);
  });

  $scope.$watch('filters.departureTo', function (departureTo, previousValue) {
    if (departureTo === previousValue) {
      return;
    }

    var data = departureTo ? moment(departureTo).format('YYYY-MM-DD') : departureTo;

    $rootScope.$broadcast('filters.departureTo', data);

    var search = $location.search();
    search.departureTo = data;
    $location.search(search);
  });

  $scope.$watch('filters.payment', function (payment, previousValue) {
    if (payment === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.payment', payment);

    var search = $location.search();
    search.payment = payment;
    $location.search(search);
  });

  $scope.$watch('filters.cartReference', function (cartReference, previousValue) {
    if (cartReference === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.cartReference', cartReference);

    var search = $location.search();
    search.cartReference = cartReference;
    $location.search(search);
  });

  $scope.$watch('filters.country', function (country, previousValue) {
    if (country === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.country', country);

    var search = $location.search();
    search.country = country;
    $location.search(search);
  });

  $scope.$watch('filters.isHandled', function (status, previousValue) {
    if (status === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.isHandled', status);

    var search = $location.search();
    search.isHandled = status;
    $location.search(search);
  });

  $scope.$watch('filters.approveMethod', function (approveMethod, previousValue) {
    if (approveMethod === previousValue) {
      return;
    }

    $rootScope.$broadcast('filters.approveMethod', approveMethod);

    var search = $location.search();
    search.approveMethod = approveMethod;
    $location.search(search);
  });

  $scope.reset = function () {
    $scope.filters = Object.assign({}, defaultFilters);
    $scope.paymentStatusModel = [{id: 0}, {id: 2}];

    $location.search($scope.filters);

    $rootScope.$broadcast('resetFilters', defaultFilters);
  };
}

'use strict';

/* exported
journeyDateSelectDirective
 */

function journeyDateSelectDirective(SearchRestangular, moment, $stateParams, $rootScope) {
  return {
    restrict: 'EA',
    require: ['ngModel'],
    scope: {
      product: '=product',
      bookingId: '=bookingId',
      locked: '=locked',
      direction: '=direction',
    },
    templateUrl: 'views/bookings/edit/journey-date-select.directive.html',
    link: function (scope, iElement, iAttrs, ctrls) {
      scope.extraOptionDefinitionsApi = {};
      scope.selectedExtraOptionsApi = {};

      function constructTrip(trip) {
        return {
          id: trip._id,
          departure: {
            date: moment.tz(trip.departure.date, trip.departure.timezone),
            timezone: trip.departure.timezone,
          },
          arrival: {
            date: moment.tz(trip.arrival.date, trip.arrival.timezone),
            timezone: trip.arrival.timezone,
          },
          isSupplierApi: trip.isSupplierApi,
          bookTimes: trip.bookTimes,
        };
      }

      function computeOptionsFromDate(date) {
        scope.options = [];
        if (date) {
          const products = _.get(scope, 'product._id') || _.get(scope, 'product.line._id');
          SearchRestangular.all('v2/trips/').getList({
            limit: 1000,
            date: date,
            froms: scope.direction === 'aToB' ? scope.product.from._id: scope.product.to._id,
            tos: scope.direction === 'aToB' ? scope.product.to._id: scope.product.from._id,
            products: products,
            fromSlug: scope.product.from.city.slug,
            toSlug: scope.product.to.city.slug,
            transportReference: scope.product.transportReference,
          })
          .then(function (trips) {
            if (trips && trips.length) {
              scope.options = trips.map(constructTrip);
            }
          });
        }
      }

      scope.selected = {};

      var ngModelCtrl = ctrls[0];

      ngModelCtrl.$formatters.push(function (modelValue) {
        return {journey: modelValue};
      });


      ngModelCtrl.$parsers.push(function (viewValue) {
        var journey = viewValue.journey;

        if (journey) {
          return journey;
        }
      });


      ngModelCtrl.$render = function () {
        var journey = ngModelCtrl.$viewValue.journey;

        if (journey && !journey.departure) {
          scope.selected = {
            journey: null
          };
        } else if (journey) {
          scope.selected = {
            date: moment(journey.departure.date).tz(journey.departure.timezone).format('YYYY-MM-DD'),
            journey: journey
          };
        }
        computeOptionsFromDate(scope.selected.date);
      };

      ngModelCtrl.$validators.required = function (modelValue) {
        return !!modelValue;
      };

      scope.onDateChange = function (date) {
        scope.selected.journey = null;
        ngModelCtrl.$setViewValue({journey: scope.selected.journey});
        computeOptionsFromDate(date);
      };

      function getSeatMap(product, journey) {
        scope.rawSeatMap = null;
        scope.extraOptionDefinitionsApi = {};

        const lineId = _.get(product, 'line._id');
        const slug = _.get(product, 'transportReference');
        const fromStation = _.get(product, 'from._id');
        const fromSlug = _.get(product, 'from.city.slug');
        const toStation = _.get(product, 'to._id');
        const toSlug = _.get(product, 'to.city.slug');
        const departureDate = moment(journey.departure.date).format('YYYY-MM-DD');
        const departureTime = moment(journey.departure.date).format('hhmmA');
        const tripId = journey.id;

        if (product.productType === 'online' || product.isSupplierApi) {
          SearchRestangular.one('/v1/routes/supplierExtras')
          .get({ slug, tripId, fromStation, toStation, departureDate, departureTime, vehicles: [], lineId, fromSlug, toSlug })
          .then(function (supplierExtras) {
            if (supplierExtras.seatMap && !scope.bookingId && (journey.isSupplierApi || scope.product.productType === 'online')) {
              supplierExtras.seatMap.floors.forEach((floor) => {
                floor.rowsArray = [];
                floor.columnArray = [];
                for (let i = 0; i < floor.numOfRows; i++ ){
                  floor.rowsArray.push(i);
                }
                for (let i = 0; i < floor.numOfColumns; i++){
                  floor.columnArray.push(i);
                }
              });
              scope.rawSeatMap = supplierExtras;
            }
            if (supplierExtras.extraOptionsApi) {
              supplierExtras.extraOptionsApi.forEach(function (entry) {
                scope.extraOptionDefinitionsApi[entry.type] = {
                    title: _.capitalize(entry.type),
                    options: entry.options.map(function (option, i) {
                      return {
                          type: entry.type,
                          selectedOption: i,
                          value: `${option.value}`,
                          name: `${option.value}`,
                          beforeAfter: option.beforeAfter,
                          time: option.time,
                          placeId: option.placeId,
                      }
                    })
                  }
              });
            }
          })
          .catch(function(err) {
            var message = '';
            if (_.isArray(err)) {
              message = err.join('<br>');
            } else {
              message = _.get(err, 'data.message') || err.message || '';
            }
            console.error('failed operation with error: \n' + message.replace(/<br>/g, '\n'));
            if(message !== 'Closed Modal') {
              $rootScope.$emit('notify', {type: 'error', title: 'Error', message: message});
            }
          });
        }
      }

      scope.selectJourney = function (journey) {
        scope.selected.journey = journey;
        ngModelCtrl.$setViewValue({journey: journey});
        if (scope.product, journey) {
          getSeatMap(scope.product, journey);
        }
      };

      scope.onExtraSelected = function (extra) {
        scope.selectedExtraOptionsApi[extra.type] = extra;
        if (!_.isEmpty(scope.selectedExtraOptionsApi)) {
          scope.selected.journey.extraOptionsApi = Object.values(scope.selectedExtraOptionsApi);
        }
      }

      scope.onSeatSelected = function (seat) {
        if (seat.isChecked) {
          if (scope.selected.journey.seats) {
            scope.selected.journey.seats.push(seat);
          } else {
            scope.selected.journey.seats = [seat];
          }
        } else {
          if (scope.selected.journey.seats) {
            var foundSeatIndex = scope.selected.journey.seats.findIndex(function (journeySeat) {
              return journeySeat.seatCode === seat.seatCode;
            });
            if (foundSeatIndex > -1) {
              scope.selected.journey.seats.splice(foundSeatIndex, 1);
            }
          }
        }
      };

      scope.filterSeatRowCol = function (rowIndex, colIndex){
        return function(seat) {
          return seat && parseInt(seat.seatRow, 10) === rowIndex + 1 && parseInt(seat.seatColumn, 10) === colIndex + 1;
        };
      }
      
      if ($stateParams.date) {
        scope.selected.date = moment($stateParams.date);
        scope.onDateChange($stateParams.date);
      }
    }
  };
}

'use strict';

/* exported
journeyStationSelectDirective
 */

function journeyStationSelectDirective() {
  return {
    restrict: 'EA',
    require: ['ngModel'],
    scope: {
      product: '=product',
      booking: '=booking',
      stationChanged: '=stationChanged',
      selectedStationId: '=selectedStationId',
      name: '@name',
    },
    templateUrl: 'views/bookings/edit/journey-station-select.directive.html',
    link: function (scope, iElement, iAttrs, ctrls) {

      scope.onSelectStation = function onSelectStation(station) {
        scope.stationChanged(station);
      }

      var ngModelCtrl = ctrls[0];

      ngModelCtrl.$formatters.push(function (modelValue) {
        return {journeyStations: modelValue};
      });

      ngModelCtrl.$parsers.push(function (viewValue) {
        var journeyStations = viewValue.journeyStations;
        if (journeyStations) {
          return journeyStations;
        }
      });

      ngModelCtrl.$render = function () {
        var journeyStations = ngModelCtrl.$viewValue.journeyStations;
        scope.journeyStations = journeyStations;
        scope.selectedStation = scope.journeyStations.find((station) => station.stationId === scope.selectedStationId);
      };

      ngModelCtrl.$validators.required = function (modelValue) {
        return !!modelValue;
      };
    }
  };
}

'use strict';

/* exported
journeyChoiceDirective
 */

function journeyChoiceDirective(Restangular, moment) {
  return {
    restrict: 'EA',
    scope: {
      from: '=',
      to: '=',
      dateString: '@',
      productType: '@',
      productId: '@',
      price: '@',
      onJourneySelected: '&',
      displayOptions: '='
    },
    templateUrl: 'views/bookings/edit/journey-choice.directive.html',
    link: function(scope) {
      function computeDepartureDateTimeFromDateString(dateString, event) {
        var departureDate = moment.tz(dateString, scope.from.timezone);
        var departureTimeDuration = moment.duration(event.start);

        departureDate.add(departureTimeDuration);

        return departureDate;
      }

      function computeArrivalDateTimeFromDateString(dateString, event) {
        var arrivalDate = moment.tz(dateString, scope.from.timezone);
        var arrivalTimeDuration = moment.duration(event.end);

        arrivalDate.add(arrivalTimeDuration);

        return arrivalDate;
      }

      function retrieveRoutes() {
        Restangular
          .all('routes')
          .getList({from: scope.from._id, to: scope.to._id})
          .then(function(routes) {
            scope.routes = routes.filter(function (route) {
              return (!scope.lineId || scope.lineId === route.line._id);
            });
          });
      }

      function initWatchers() {
        scope.$watch('to', function(curr, prev) {
          if (curr._id === prev._id) {
            return;
          }

          if (scope.from) {
            retrieveRoutes();
          }
        });

        scope.$watch('from', function(curr, prev) {
          if (curr._id === prev._id) {
            return;
          }

          if (scope.to) {
            retrieveRoutes();
          }
        });

        scope.$watch('dateString', function(curr, prev) {
          if (curr === prev) {
            return;
          }

          scope.dayOfWeek = parseInt(moment.tz(curr, scope.from.timezone).format('d'), 10);
        });
      }

      retrieveRoutes();
      scope.dayOfWeek = parseInt(moment.tz(scope.dateString, scope.from.timezone).format('d'), 10);
      initWatchers();

      scope.getDepartureDateFromDateString = function(dateString, event) {
        var departureDateTime = computeDepartureDateTimeFromDateString(dateString, event);

        return departureDateTime.format('ddd, MMM Do YYYY');
      };

      scope.getDepartureTimeFromDateString = function(dateString, event) {
        var departureDateTime = computeDepartureDateTimeFromDateString(dateString, event);

        return departureDateTime.format('HH:mm');
      };

      scope.getArrivalDateFromDateString = function(dateString, event) {
        var arrivalDateTime = computeArrivalDateTimeFromDateString(dateString, event);

        return arrivalDateTime.format('ddd, MMM Do YYYY');
      };

      scope.getArrivalTimeFromDateString = function(dateString, event) {
        var arrivalDateTime = computeArrivalDateTimeFromDateString(dateString, event);

        return arrivalDateTime.format('HH:mm');
      };

      scope.select = function (route, event) {
        scope.selectedRoute = route;

        scope.journey = {
          productType: 'line',
          product: route.line,
          from: route.from,
          to: route.to,
          departure: {
            date: computeDepartureDateTimeFromDateString(scope.dateString, event),
            timezone: scope.from.timezone
          },
          arrival: {
            date: computeArrivalDateTimeFromDateString(scope.dateString, event),
            timezone: scope.to.timezone
          }
        };

        scope.onBookingItemSelected()({route: scope.selectedRoute, journey: scope.journey});
      };
    }
  };
}

/**
 * Created by john on 28/10/16.
 */
"use strict";

/* exported
extraOptionsNewDirective
 */

function extraOptionsNewDirective() {
  return {
    restrict: "AE",
    require: ["ngModel", "^form"],
    templateUrl: "views/bookings/edit/extra-options-new.html",
    scope: {
      extraOptionDefinitions: "=",
      name: "@",
      from: "@",
      to: "@",
      originalOptions: "<",
      extrasForExchange: "<",
      previosDirections: "<",
      currentDirections: "<",
    },
    link: function (scope, iElement, iAttrs, ctrls) {
      scope.filterDeleted = function (extra) {
        return !extra.deleted;
      };

      var extraOptionLabels = {
        pickup_hotel: "Hotel Pick Up in " + scope.from,
        pickup_airport: "Airport Pick Up in " + scope.from,
        dropoff_hotel: "Hotel Drop Off in " + scope.to,
        airport_dropoff: "Airport Drop Off in " + scope.to,
      };

      var ngModelCtrl = ctrls[0];
      scope.parentForm = ctrls[1];

      var originalOptionsMap = {};
      (scope.originalOptions || []).forEach(function (option) {
        originalOptionsMap[option.definition] = option;
      });

      var extraOptionDefinitionMap = {};
      scope.$watch(
        "extraOptionDefinitions",
        function (newValue) {
          extraOptionDefinitionMap = {};
          newValue.forEach(function (optionDefinition) {
            extraOptionDefinitionMap[optionDefinition._id] = optionDefinition;
          });
        },
        true
      );

      if (scope.name && scope.parentForm) {
        scope.$watch(
          function () {
            return scope.parentForm[scope.name].$touched;
          },
          function (isTouched) {
            if (isTouched) {
              for (var i = 0; i < scope.extraOptionDefinitions.length; i++) {
                var extraOptionDefinition = scope.extraOptionDefinitions[i];

                if (scope.parentForm[extraOptionDefinition._id]) {
                  scope.parentForm[extraOptionDefinition._id].$setTouched();
                }
              }
            }
          }
        );
      }

      // From Model to View
      ngModelCtrl.$formatters.push(function (modelValue) {
        var extraOptionsViewValue = {};

        (modelValue || []).forEach(function (extraOption) {
          extraOptionsViewValue[extraOption.definition] = {
            selected: true,
            value: extraOption.value,
            _id: extraOption._id,
            definition: extraOption.definition,
          };
        });

        if (
          scope.name === "oneWayExtraOptions" &&
          !modelValue.length &&
          scope.extraOptionDefinitions.length &&
          scope.extrasForExchange &&
          scope.extrasForExchange.oneWayExchange.length
        ) {
          const oneWayPickUpHotel =
            scope.extrasForExchange.oneWayExchange.filter((elem) => {
              return elem.extraType === "pickup_hotel";
            });
          const oneWayDropOffHotel =
            scope.extrasForExchange.oneWayExchange.filter((elem) => {
              return elem.extraType === "dropoff_hotel";
            });
          const oneWayPickUpAirport =
            scope.extrasForExchange.oneWayExchange.filter((elem) => {
              return elem.extraType === "pickup_airport";
            });
          const oneWayDropOffAirport =
            scope.extrasForExchange.oneWayExchange.filter((elem) => {
              return elem.extraType === "dropoff_airport";
            });
          const oneWayUpgrade = scope.extrasForExchange.oneWayExchange.filter(
            (elem) => {
              return elem.extraType === "upgrade";
            }
          );

          scope.extraOptionDefinitions.forEach(function (extraOption) {
            if (
              extraOption.extraType === "pickup_hotel" &&
              oneWayPickUpHotel.length &&
              scope.previosDirections.oneWayTrip.from ===
                scope.currentDirections.oneWayTrip.from
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: oneWayPickUpHotel[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (
              extraOption.extraType === "dropoff_hotel" &&
              oneWayDropOffHotel.length &&
              scope.previosDirections.oneWayTrip.to ===
                scope.currentDirections.oneWayTrip.to
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: oneWayDropOffHotel[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (
              extraOption.extraType === "pickup_airport" &&
              oneWayPickUpAirport.length &&
              scope.previosDirections.oneWayTrip.from ===
                scope.currentDirections.oneWayTrip.from
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: oneWayPickUpAirport[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (
              extraOption.extraType === "dropoff_airport" &&
              oneWayDropOffAirport.length &&
              scope.previosDirections.oneWayTrip.to ===
                scope.currentDirections.oneWayTrip.to
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: oneWayDropOffAirport[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (extraOption.extraType === "upgrade" && oneWayUpgrade.length) {
              extraOptionsViewValue[extraOption._id] = {
                selected: oneWayUpgrade[0].value ? true : false,
                value: !oneWayUpgrade[0].value ? "" : oneWayUpgrade[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
          });
        }

        if (
          scope.name === "twoWayExtraOptions" &&
          !modelValue.length &&
          scope.extraOptionDefinitions.length &&
          scope.extrasForExchange &&
          scope.extrasForExchange.twoWayExchange.length
        ) {
          const twoWayPickUpHotel =
            scope.extrasForExchange.twoWayExchange.filter((elem) => {
              return elem.extraType === "pickup_hotel";
            });
          const twoWayDropOffHotel =
            scope.extrasForExchange.twoWayExchange.filter((elem) => {
              return elem.extraType === "dropoff_hotel";
            });
          const twoWayPickUpAirport =
            scope.extrasForExchange.twoWayExchange.filter((elem) => {
              return elem.extraType === "pickup_airport";
            });
          const twoWayDropOffAirport =
            scope.extrasForExchange.twoWayExchange.filter((elem) => {
              return elem.extraType === "dropoff_airport";
            });
          const twoWayUpgrade = scope.extrasForExchange.twoWayExchange.filter(
            (elem) => {
              return elem.extraType === "upgrade";
            }
          );

          scope.extraOptionDefinitions.forEach(function (extraOption) {
            if (
              extraOption.extraType === "pickup_hotel" &&
              twoWayPickUpHotel.length &&
              scope.previosDirections.twoWayTrip.from ===
                scope.currentDirections.twoWayTrip.from
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: twoWayPickUpHotel[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (
              extraOption.extraType === "dropoff_hotel" &&
              twoWayDropOffHotel.length &&
              scope.previosDirections.twoWayTrip.to ===
                scope.currentDirections.twoWayTrip.to
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: twoWayDropOffHotel[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (
              extraOption.extraType === "pickup_airport" &&
              twoWayPickUpAirport.length &&
              scope.previosDirections.twoWayTrip.from ===
                scope.currentDirections.twoWayTrip.from
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: twoWayPickUpAirport[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (
              extraOption.extraType === "dropoff_airport" &&
              twoWayDropOffAirport.length &&
              scope.previosDirections.twoWayTrip.to ===
                scope.currentDirections.twoWayTrip.to
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: twoWayDropOffAirport[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
            if (extraOption.extraType === "upgrade" && twoWayUpgrade.length) {
              extraOptionsViewValue[extraOption._id] = {
                selected: twoWayUpgrade[0].value ? true : false,
                value: !twoWayUpgrade[0].value ? "" : twoWayUpgrade[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
          });
        }

        if (
          scope.name === "upgradeExtraOptions" &&
          !modelValue.length &&
          scope.extraOptionDefinitions.length &&
          scope.extrasForExchange &&
          scope.extrasForExchange.upgradeExchange.length
        ) {
          const upgradeOptions = scope.extrasForExchange.upgradeExchange.filter(
            (elem) => {
              let comparedDefinition;
              for (let i = 0; i < scope.extraOptionDefinitions.length; i++) {
                comparedDefinition = scope.extraOptionDefinitions[i];
              }
              return elem.label === comparedDefinition.upgrade;
            }
          );

          scope.extraOptionDefinitions.forEach(function (extraOption) {
            if (
              extraOption.extraType === "upgrade" &&
              upgradeOptions.length &&
              extraOption.upgrade === upgradeOptions[0].label
            ) {
              extraOptionsViewValue[extraOption._id] = {
                selected: true,
                value: !upgradeOptions[0].value ? "" : upgradeOptions[0].value,
                _id: extraOption._id,
                definition: extraOption._id,
              };
            }
          });
        }

        return extraOptionsViewValue;
      });

      ngModelCtrl.$render = function () {
        scope.extraOptionsViewValue = ngModelCtrl.$viewValue;
      };

      // From View to Model
      ngModelCtrl.$parsers.push(function (viewValue) {
        var modelValue = [];

        Object.keys(viewValue).forEach(function (key) {
          var extra = viewValue[key];
          extra.definition = key;
          extra._id = key;
          extra.price =
            (originalOptionsMap[key] && originalOptionsMap[key].price) ||
            extraOptionDefinitionMap[key].price;
          extra.label =
            extraOptionDefinitionMap[key].upgrade ||
            extraOptionLabels[extraOptionDefinitionMap[key].extraType];
          extra.perPassenger = extraOptionDefinitionMap[key].perPassenger;

          if (extraOptionDefinitionMap[key].mandatory) {
            extra.selected = true;
          }

          if (extra.selected) {
            modelValue.push(extra);
          }
        });
        return modelValue;
      });

      scope.$watch(
        "extraOptionsViewValue",
        function (newValue) {
          ngModelCtrl.$setViewValue(Object.assign({}, newValue));
        },
        true
      );
    },
  };
}

/**
 * Created by john on 28/10/16.
 */
"use strict";

/* exported
extraOptionsApiDirective
 */

function extraOptionsApiDirective() {
  return {
    restrict: "AE",
    require: ["^ngModel", "^form"],
    templateUrl: "views/bookings/edit/extra-options-api.html",
    scope: {
      extraOptionDefinitionsApi: "=",
      onExtraSelected: "<",
    },
    link: function (scope, iElement, iAttrs, ctrls) {
      scope.item = {
        selected: null
      };
    },
  };
}

'use strict';

/* exported
bookingPassengersDirective
 */

function bookingPassengersDirective(moment) {
  return {
    restrict: 'EA',
    require: ['ngModel', '^form'],
    scope: {
      passengersNumberString: '@passengersNumber',
      extraInfoDefinitions: '=',
      minPassengers: '=',
      isPrivate: '=',
      passengerTypes: '=',
      ageRequired: '=',
      name: '@',
      isB2b: '=',
    },
    templateUrl: 'views/bookings/edit/passengers.directive.html',
    link: function (scope, iElement, iAttrs, ctrls) {
      const extrasIds = {
        ageExtra: '58f47da902e97f000888b000',
        birthdayExtra: '5b94e26a8c315b8938e76e80',
      }

      function extratAgeFromBirthday(birthday) {
       return moment().diff(moment(birthday), 'years')
      } 

      function addPassenger(withContact) {
        var passenger = {};

        if (scope.isPrivate) {
          passenger.firstName = 'private';
          passenger.lastName = 'private';
          passenger.passengerType = '';
        }

        if (withContact) {
          passenger.contact = {};
        }

        scope.passengers.push(passenger);
      }

      function removePassenger() {
        scope.passengers.pop();
      }


      var ngModelCtrl = ctrls[0];
      scope.parentForm = ctrls[1];

      if (scope.name && scope.parentForm) {
        scope.$watch(function() {
          return scope.parentForm[scope.name].$touched;
        }, function(isTouched) {
          if (isTouched) {
            scope.passengers.forEach(function(passenger, i) {
              scope.parentForm['firstname-' + i].$setTouched();
              scope.parentForm['lastname-' + i].$setTouched();
              scope.parentForm['age-' + i].$setTouched();

              if (i === 0) {
                scope.parentForm['email-' + i].$setTouched();
              }

              scope.extraInfoDefinitions.forEach(function(extraInfoDefinition) {
                scope.parentForm[extraInfoDefinition._id + '-' + i].$setTouched();
              });
            });
          }
        });
      }

      scope.passengers = [];
      scope.passengersNumber = parseInt(scope.passengersNumberString || 1, 10);

      if (scope.isPrivate) {
        scope.limit = 1;
      }
      scope.$watch('isPrivate', function(isPrivate) {
        if (isPrivate) {
          scope.limit = 1;
        } else {
          scope.limit = scope.passengersNumber;
        }
      })

      scope.addPassenger = function() {
        scope.passengersNumber++;
      }

      scope.removePassenger = function(passangerIndex) {
        scope.passengersNumber--;
        scope.passengers.splice(passangerIndex, 1);
      }

      scope.$watch('passengersNumberString', function(val) {
        scope.passengersNumber = parseInt(val || 1, 10);
      });

      scope.$watch('passengersNumber', function(cur) {
        var prev = scope.passengers.length;
        var diff = cur - prev;

        if (!scope.isPrivate) {
          scope.limit = cur;
        }

        if (diff > 0) {
          for (var i = 0; i < diff; i++) {
            var withContact = (i === 0 && !prev);

            addPassenger(withContact);
          }
        } else if (diff < 0) {
          for (var j = 0; j < -diff; j++) {
            removePassenger();
          }
        }
      });

      ngModelCtrl.$formatters.push(function(modelValue) {
        return {passengers: modelValue};
      });

      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.passengers;
      });

      scope.$watchCollection('passengers', function(newVal, oldVal) {
        newVal.forEach(function(passenger, index) {
          scope.$watchGroup(['passengers[' + index + '].extraInfos'], function(extraInfos) {
            const birthdayExtra = (extraInfos && extraInfos[0] || []).find((info) => info.definition === extrasIds.birthdayExtra);
            const ageExtra = (extraInfos && extraInfos[0] || []).find((info) => info.definition === extrasIds.ageExtra);

            let ageValue;
            if (birthdayExtra) {
              ageValue = extratAgeFromBirthday(birthdayExtra.value);
            } 
            if (ageExtra) {
              ageValue = ageExtra.value
            }
            const passengerType = scope.passengerTypes.find(i => parseInt(ageValue) >= i.fromAge && parseInt(ageValue) <= i.toAge);
            passenger.passengerType = scope.ageRequired && passengerType ? passengerType.id : '';
            passenger.age = ageValue;
          });
        });
      });
  
      ngModelCtrl.$render = function() {
        if (ngModelCtrl.$viewValue.passengers) {
          scope.passengers = ngModelCtrl.$viewValue.passengers;
        } else {
          for (var i = 0; i < scope.passengersNumber; i++) {
            var passenger = i === 0 ? {contact: {}} : {};

            scope.passengers.push(passenger);
          }
        }
      };

      scope.$watch('passengers', function(passengers) {
        ngModelCtrl.$setViewValue({passengers: passengers});
      }, true);
    }
  };
}

'use strict';

/* exported
packSelectDirective
 */

function packSelectDirective() {
    return {
      restrict: 'EA',
      require: ['ngModel'],
      scope: {
        packs: '='
      },
      templateUrl: 'views/bookings/edit/pack-select.directive.html',
      link: function (scope, iElement, iAttrs, ctrls) {
        scope.areOptionsDisplayed = true;
        var ngModelCtrl = ctrls[0];

        ngModelCtrl.$formatters.push(function(modelValue) {
          return {pack: modelValue};
        });

        ngModelCtrl.$parsers.push(function(viewValue) {
          return viewValue.pack;
        });

        ngModelCtrl.$render = function() {
          var packId = ngModelCtrl.$viewValue.pack;

          scope.pack = scope.packs.find(function(pack) {
            return pack._id === packId;
          });
        };

        ngModelCtrl.$validators.required = function(modelValue) {
          return !!modelValue;
        };

        scope.selectPack = function(pack) {
          scope.pack = pack;
          ngModelCtrl.$setViewValue({pack: pack._id});
          scope.areOptionsDisplayed = false;
        };

        scope.change = function() {
          scope.areOptionsDisplayed = true;
        };
      }
  };
}

'use strict';

/* exported
dateStringDirective
 */

function dateStringDirective(moment) {
  return {
    restrict: 'EA',
    require: 'ngModel',
    scope: {
      ngMin: '=',
      ngMax: '=',
      ngDisabled: '=',
      ngRequired: '='
    },
    templateUrl: 'views/bookings/edit/date-string.directive.html',
    link: function(scope, iElement, iAttrs, ngModelCtrl) {

      var initialMin = moment(scope.ngMin).toDate() || new Date();
      var initialMax = scope.ngMax || moment().add(1, 'year').toDate();

      scope.$watch('ngMin', function(newValue, oldValue) {
        if (newValue !== oldValue) {
          ngModelCtrl.$validate();
        }
      });

      ngModelCtrl.$validators.required = function(modelValue) {
        return !iAttrs.required || !!modelValue;
      };

      ngModelCtrl.$validators.ngMin = function(modelValue) {
        return (scope.ngMin && modelValue) ? moment(modelValue).isAfter(moment(scope.ngMin)) : true;
      };


      // From Model to View
      ngModelCtrl.$formatters.push(function(modelValue) {
        var date = modelValue ? moment(modelValue) : null;

        return {date: date};
      });

      // Render View
      ngModelCtrl.$render = function() {
        var viewValue = ngModelCtrl.$viewValue;

        scope.dateModel = viewValue.date ? viewValue.date.toDate() : null;
      };

      // From View to Model
      ngModelCtrl.$parsers.push(function(viewValue) {
        return viewValue.date ? moment(viewValue.date).format('YYYY-MM-DD') : null;
      });

      // When dateModel changes
      scope.$watch('dateModel', function(newValue, oldValue) {
        if (newValue !== oldValue) {
          ngModelCtrl.$setViewValue({date: newValue});
          ngModelCtrl.$setTouched();
        }
      });


      scope.datePopup = {
        opened: false
      };

      scope.openDatePopup = function() {
        // Check that line has been selected before
        scope.datePopup.opened = true;
        ngModelCtrl.$setTouched();
      };

      scope.dateOptions = {
        formatYear: 'yy',
        maxDate: initialMax,
        minDate: initialMin,
        startingDay: 1
      };
    }
  };
}

'use strict';

/* exported
bookingViewModalService
 */

function changeBookingLineModalService($uibModal) {
  /* jshint validthis: true */
  this.show = function (basicCurrency) {
    return $uibModal.open({
      templateUrl: 'views/bookings/search.html',
      controller: 'BookingSearchModalCtrl',
      resolve: {
        modal: true,
        basicCurrency: [
          function() { return basicCurrency; }
        ],
      },
      backdrop: true,
      animation: false
    })
      .result
      .then(function (res) {
        return res.product;
      });
  };
}

'use strict';

/* exported
menuEditBookingCtrl
 */

function menuEditBookingCtrl($scope, booking, $rootScope, $window, appConfig, $state) {
  $scope.booking = booking;

  $scope.save = function() {
    $rootScope.$broadcast('booking.save');
  };

  $scope.cancel = function() {
    $state.go('root.bookings');
  };

  $scope.getTrackingUrl = function() {
    return appConfig.frontendBrowserUrl + '/track?email=' + booking.items[0].passengers[0].contact.email + '&reference=' + booking.reference;
  };
}

/**
 * Created by john on 23/10/16.
 */
'use strict';

/* exported
 editBookingCtrl
 */

function editBookingCtrl($scope, $uibModal, booking, lineEditService, $state, Restangular, product, $rootScope, $location, changeBookingLineModal, applications, passengerTypeDefinitions, user, journeys, CurrenciesService, SearchRestangular) {
  $scope.lineEditService = lineEditService;
  $scope.applications = applications || [];
  $scope.oldBooking = {};
  $scope.journeyStations = { fromStations: [], toStations: [] };
  $scope.returnJourneyStations = { fromStations: [], toStations: [] };
  $scope.fromStationId = null;
  $scope.toStationId = null;
  $scope.returnFromStationId = null;
  $scope.returnToStationId = null;
  $scope.isNotUsd = booking.basicCurrency !== 'USD';
  $scope.currencySign = CurrenciesService.getCurrencySign(booking.basicCurrency);
  $scope.isPassengerExtraInfoDefinitionsLoaded = false;


  if (journeys && journeys.length > 0) {
    $scope.journeyStations =
    {
      fromStations: [...journeys[0].bookTimes[0].fromStations],
      toStations: [...journeys[0].bookTimes[0].toStations],
    };
    for (let i = 0; i < $scope.journeyStations.fromStations.length; i = i + 1) {
      $scope.journeyStations.fromStations[i].name = '';
    }
    for (let i = 0; i < $scope.journeyStations.toStations.length; i = i + 1) {
      $scope.journeyStations.toStations[i].name = '';
    }
  }
  if (journeys && journeys.length > 1) {
    $scope.returnJourneyStations =
    {
      fromStations: [...journeys[1].bookTimes[0].fromStations],
      toStations: [...journeys[1].bookTimes[0].toStations],
    };
    for (let i = 0; i < $scope.returnJourneyStations.fromStations.length; i = i + 1) {
      $scope.returnJourneyStations.fromStations[i].name = '';
    }
    for (let i = 0; i < $scope.returnJourneyStations.toStations.length; i = i + 1) {
      $scope.returnJourneyStations.toStations[i].name = '';
    }
  }

  $scope.line = null;

  function loadPassengerExtraInfoDefinitions(product, journey) {
    const lineId = _.get(product, '_id') || _.get(product, 'line._id');
    const slug = _.get(product, 'transportReference');
    const fromStation = _.get(product, 'from._id');
    const toStation = _.get(product, 'to._id');
    const departureDate = moment(journey.departure.date).format('YYYY-MM-DD');
    const departureTime = moment(journey.departure.date).format('hhmmA');
    SearchRestangular.all('v1/routes/passengerExtraInfoDefinitions')
    .getList({ slug, lineId, fromStation, toStation, departureDate, departureTime })
    .then(function(passengerExtraInfoDefinitions) {
      $scope.isPassengerExtraInfoDefinitionsLoaded = true;
      $scope.product.passengerExtraInfoDefinitions = passengerExtraInfoDefinitions;
    });
  }

  $scope.journeyChanged = function journeyChanged(journey) {
    if (journey) {
      loadPassengerExtraInfoDefinitions($scope.product, journey);
      $scope.journeyStations = {
        fromStations: [...journey.bookTimes.fromStations],
        toStations: [...journey.bookTimes.toStations],
      }
      if (!$scope.product.transportReference) {
        const multiStations = $scope.product.direction === 'fromAtoB' ? $scope.line.aToBMultiStations : $scope.line.bToAMultiStations;
        for (let i = 0; i < $scope.journeyStations.fromStations.length; i = i + 1) {
          $scope.journeyStations.fromStations[i].name = multiStations.find((station) => {
            return station.stationId._id === $scope.journeyStations.fromStations[i].stationId;
          }).stationId.name;
        }
        for (let i = 0; i < $scope.journeyStations.toStations.length; i = i + 1) {
          $scope.journeyStations.toStations[i].name = multiStations.find((station) => {
            return station.stationId._id === $scope.journeyStations.toStations[i].stationId;
          }).stationId.name;
        }
      }
      $scope.fromStationId = journey.bookTimes.fromStations.find(x => x.isPrimary).stationId;
      $scope.toStationId = journey.bookTimes.toStations.find(x => x.isPrimary).stationId;
    }
    else {
      $scope.journeyStations = { fromStations: [], toStations: [] };
      $scope.fromStationId = null;
      $scope.toStationId = null;
    }
  }

  $scope.returnJourneyChanged = function returnJourneyChanged(journey) {
    if (journey) {
      const multiStations = $scope.product.direction === 'fromAtoB' ? $scope.line.bToAMultiStations : $scope.line.aToBMultiStations;
      $scope.returnJourneyStations = {
        fromStations: [...journey.bookTimes.fromStations],
        toStations: [...journey.bookTimes.toStations],
      }
      for (let i = 0; i < $scope.returnJourneyStations.fromStations.length; i = i + 1) {
        $scope.returnJourneyStations.fromStations[i].name = multiStations.find((station) => {
          return station.stationId._id === $scope.returnJourneyStations.fromStations[i].stationId;
        }).stationId.name;
      }
      for (let i = 0; i < $scope.returnJourneyStations.toStations.length; i = i + 1) {
        $scope.returnJourneyStations.toStations[i].name = multiStations.find((station) => {
          return station.stationId._id === $scope.returnJourneyStations.toStations[i].stationId;
        }).stationId.name;
      }
      $scope.returnFromStationId = journey.bookTimes.fromStations.find(x => x.isPrimary).stationId;
      $scope.returnToStationId = journey.bookTimes.toStations.find(x => x.isPrimary).stationId;
    }
    else {
      $scope.returnJourneyStations = { fromStations: [], toStations: [] };
      $scope.returnFromStationId = null;
      $scope.returnToStationId = null;
    }
  }

  $scope.fromStationChanged = function fromStationChanged(station) {
    $scope.fromStationId = station.stationId;
  }

  $scope.toStationChanged = function toStationChanged(station) {
    $scope.toStationId = station.stationId;
  }

  $scope.returnFromStationChanged = function returnFromStationChanged(station) {
    $scope.returnFromStationId = station.stationId;
  }

  $scope.returnToStationChanged = function returnToStationChanged(station) {
    $scope.returnToStationId = station.stationId;
  }

  function getType() {
    return 'line';
  }

  $scope.getProductType = function (product) {
    return _.get(product, 'productType');
  }

  function filterOldItemExtraOptions(extraOptions) {
    return extraOptions
      .filter(function (extraOption) {
        return !extraOption.perBooking;
      })
      .map(function (extraOption) {
        var result = { definition: extraOption.definition };

        if (extraOption.value) {
          result.value = extraOption.value;
        }

        return result;
      });
  }

  function filterOldBookingExtraOptions(extraOptions) {
    return extraOptions
      .filter(function (extraOption) {
        return !!extraOption.perBooking;
      })
      .map(function (extraOption) {
        var result = { definition: extraOption.definition };

        if (extraOption.value) {
          result.value = extraOption.value;
        }

        return result;
      });
  }

  function computeItem(product, journey, passengers, extraOptions, isNewBooking, productType) {
    var productId = productType === 'online' ? product.transportReference: _.get(product, 'line._id') || product._id;

    var result = {
      productType: productType,
      product: productId,
      company: product.company._id,
      passengers: passengers,
      journey: journey.id,
      extraOptions: extraOptions,
      basicPrice: journey.price
    };

    if (isNewBooking && journey.seats && journey.seats.length) {
      if (journey.seats.length !== passengers.length) {
        $scope.$emit('notify', { type: 'error', title: 'Seats', message: 'Number of selected seats doesnt match number of passengers' });
        return;
      }
      result.seats = journey.seats;
    }

    if (isNewBooking && journey.extraOptionsApi && journey.extraOptionsApi.length) {
      result.extraOptionsApi = journey.extraOptionsApi;
    }
    return result;
  }

  function getJourneyFromItem(item) {
    if (!item) {
      return null;
    }

    return {
      id: item.trip.extendedId,
      departure: item.trip.departure,
      arrival: item.trip.arrival,
    };
  }

  function getInitialBookingForm(booking, resetBooking) {
    if (booking._id) {
      var passengers = booking.items[0].passengers;
      var extraOptions = resetBooking ? [] : Array.prototype.concat([],
        booking.extraOptions.map(function (extraOption) {
          return Object.assign({}, extraOption, { perBooking: false });
        }),
        booking.items[0].extraOptions.map(function (extraOption) {
          return Object.assign({}, extraOption, { perBooking: true });
        }));

      var passengersNumber = passengers.length;

      var roundTrip = booking.items.length === 2;
      var journeyOne = resetBooking ? {} : getJourneyFromItem(booking.items[0]);
      var journeyTwo = resetBooking ? {} : getJourneyFromItem(booking.items[1]);

      if (!_.isEmpty(journeyOne)) {
        loadPassengerExtraInfoDefinitions($scope.product, journeyOne);
      }

      var initialBookingForm = {
        extraOptions: extraOptions,
        passengers: passengers,
        roundTrip: roundTrip,
        passengersNumber: passengersNumber,
        journeyOne: journeyOne,
        payment: booking.payment.status,
        status: booking.status,
        customerStatus: booking.customerStatus,
        amendStatus: booking.amendStatus,
        oneWayExtraOptions: resetBooking ? [] : booking.items[0].extraOptions,
        twoWayExtraOptions: resetBooking ? [] : (booking.items[1] && booking.items[1].extraOptions || []),
        upgradeExtraOptions: resetBooking ? [] : booking.extraOptions,
        domain: booking.domain,
        markBookingAsPaid: false,
      };

      if (journeyTwo) {
        initialBookingForm.journeyTwo = journeyTwo;
      }

      if (booking.items[0].pack) {
        initialBookingForm.pack = booking.items[0].pack;
      }

      return initialBookingForm;
    } else {
      return {
        extraOptions: [],
        oneWayExtraOptions: [],
        twoWayExtraOptions: [],
        upgradeExtraOptions: [],
        passengers: [],
        roundTrip: false,
        passengersNumber: 1,
        domain: 'bookaway',
        markBookingAsPaid: false,
      };
    }
  }

  function filterExtraOptionsDefinitions(filter, extras) {
    var extraOptionsDefinitions = extras || [];
    if (filter === 'oneWay' || filter === 'twoWay') {
      var filterMap = {
        'oneWay': $scope.product.direction === 'fromAtoB' ? 'display_A_to_B' : 'display_B_to_A',
        'twoWay': $scope.product.direction === 'fromBtoA' ? 'display_A_to_B' : 'display_B_to_A'
      };
      return extraOptionsDefinitions.filter(extraOptionDefinition=>{
        return !extraOptionDefinition.deleted && extraOptionDefinition.priceLevel === filterMap[filter];
      });
    } else {
      return extraOptionsDefinitions.filter(function (extraOptionDefinition) {
        return (extraOptionDefinition.priceLevel !== 'display_A_to_B' && extraOptionDefinition.priceLevel !== 'display_B_to_A');
      });
    }
  }

  $scope.payload = {
    isFraudulent: booking.isFraudulent,
  };

  $scope.booking = booking;
  function setProduct(product, resetForm) {
    $scope.product = product;

    $scope.passengerTypes = _.get(product, 'company.passengerTypes') || passengerTypeDefinitions.map(ptd => {
      ptd.id = ptd._id;
      return ptd;
    });
    const productsHasPriceExceptionForPassengersTypes = (
      product.line && product.line.priceExceptions || []
    ).find(
      (exception) => exception.passengerTypes && exception.passengerTypes.length
    );
    $scope.ageRequired = productsHasPriceExceptionForPassengersTypes;

    ($scope.product.line ?
    lineEditService.retrieveServiceForLine($scope.product.line._id):
    Promise.resolve({line: $scope.product}))
      .then(data => {
      $scope.line = data.line;
      if (!resetForm) {
        const multiStations = $scope.product.direction === 'fromAtoB' ? $scope.line.aToBMultiStations : $scope.line.bToAMultiStations;
        const returnMultiStations = $scope.product.direction === 'fromAtoB' ? $scope.line.bToAMultiStations : $scope.line.aToBMultiStations;
        if (!$scope.product.transportReference) {
          for (let i = 0; i < $scope.journeyStations.fromStations.length; i = i + 1) {
            $scope.journeyStations.fromStations[i].name = multiStations.find((station) => {
              return station.stationId._id === $scope.journeyStations.fromStations[i].stationId;
            }).stationId.name;
          }
          for (let i = 0; i < $scope.journeyStations.toStations.length; i = i + 1) {
            $scope.journeyStations.toStations[i].name = multiStations.find((station) => {
              return station.stationId._id === $scope.journeyStations.toStations[i].stationId;
            }).stationId.name;
          }
        }
        if ($scope.journeyStations.fromStations.length > 0) {
          $scope.fromStationId = $scope.journeyStations.fromStations.find(x => x.isPrimary).stationId;
        }
        if ($scope.journeyStations.toStations.length > 0) {
          $scope.toStationId = $scope.journeyStations.toStations.find(x => x.isPrimary).stationId;
        }

        if (!$scope.product.transportReference) {
          for (let i = 0; i < $scope.returnJourneyStations.fromStations.length; i = i + 1) {
            $scope.returnJourneyStations.fromStations[i].name = returnMultiStations.find((station) => {
              return station.stationId._id === $scope.returnJourneyStations.fromStations[i].stationId;
            }).stationId.name;
          }
          for (let i = 0; i < $scope.returnJourneyStations.toStations.length; i = i + 1) {
            $scope.returnJourneyStations.toStations[i].name = returnMultiStations.find((station) => {
              return station.stationId._id === $scope.returnJourneyStations.toStations[i].stationId;
            }).stationId.name;
          }
        }
        if ($scope.returnJourneyStations.fromStations.length > 0) {
          $scope.returnFromStationId = $scope.returnJourneyStations.fromStations.find(x => x.isPrimary).stationId;
        }
        if ($scope.returnJourneyStations.fromStations.length > 0) {
          $scope.returnToStationId = $scope.returnJourneyStations.toStations.find(x => x.isPrimary).stationId;
        }
        if (booking.items) {
          $scope.fromStationId = booking.items[0].trip.fromId._id;
          $scope.toStationId = booking.items[0].trip.toId._id;
        }
        if (booking.items && booking.items.length === 2) {
          $scope.returnFromStationId = booking.items[1].trip.fromId._id;
          $scope.returnToStationId = booking.items[1].trip.toId._id;
        }
        $scope.journeyStations = { fromStations: [...$scope.journeyStations.fromStations], toStations: [...$scope.journeyStations.toStations] };
        $scope.returnJourneyStations = { fromStations: [...$scope.returnJourneyStations.fromStations], toStations: [...$scope.returnJourneyStations.toStations] };
      }
      else {
        $scope.journeyStations = { fromStations: [], toStations: [] };
        $scope.fromStationId = null;
        $scope.toStationId = null;
        $scope.returnJourneyStations = { fromStations: [], toStations: [] };
        $scope.returnFromStationId = null;
        $scope.returnToStationId = null;
      }
    });
    const stationExtras = _.get($scope.booking, 'items[0].transferData.stationExtras',[]);
    const lineExtras = _.get($scope,'product.extras',[]);
    const allExtras = [...lineExtras, ...stationExtras, ..._.get($scope,'product.oldExtras',[])];
    if (!resetForm && _.get($scope.booking, 'items[0].extraOptions.length')) {
      $scope.isNewExtras = false;

      var firstExtra = $scope.booking.items[0].extraOptions[0];

      var newExtra = allExtras.find(function (definition) {
        return definition._id === firstExtra.definition;
      });

      if (newExtra) {
        $scope.isNewExtras = true;
      }
    } else {
      $scope.isNewExtras = (allExtras.length > 0) || ($scope.product.extraOptionDefinitions && $scope.product.extraOptionDefinitions.length === 0);
    }
    if ($scope.oldBooking._id) {
      $scope.previosDirections = {
        oneWayTrip: {
          from: $scope.oldBooking.items[0].trip.fromId.city.name,
          to: $scope.oldBooking.items[0].trip.toId.city.name
        },
        twoWayTrip: $scope.oldBooking.items.length > 1 ? {
          from: $scope.oldBooking.items[1].trip.fromId.city.name,
          to: $scope.oldBooking.items[1].trip.toId.city.name
        } : void 0
      }
      $scope.currentDirections = {
        oneWayTrip: {
          from: product.from.city.name,
          to: product.to.city.name
        },
        twoWayTrip: {
          from: product.to.city.name,
          to: product.from.city.name
        }
      }
      $scope.extrasForExchange = {
        oneWayExchange: $scope.oldBooking.items[0].extraOptions || [],
        twoWayExchange: $scope.oldBooking.items.length > 1 ? $scope.oldBooking.items[1].extraOptions : [],
        upgradeExchange: $scope.bookingForm.upgradeExtraOptions || []
      }
      $scope.bookingForm = getInitialBookingForm($scope.oldBooking, true);
    } else {
      $scope.bookingForm = getInitialBookingForm($scope.booking, resetForm);
    }

    $scope.payload = { items: [], isFraudulent: booking.isFraudulent };
    const extraOptions = _.get($scope,'booking.items[0].extraOptions',[]);
    let oldExtras = _.get($scope, 'product.oldExtras',[]);
    oldExtras = oldExtras.map(x=> {
      if(product.direction === 'fromBtoA') {
        x.priceLevel = x.priceLevel === 'display_A_to_B' ? 'display_B_to_A' : 'display_A_to_B';
      }
      return x;
    })
    const isStationExtras = _.intersectionWith(extraOptions,oldExtras.filter(x=>!x.extraType==='upgrade'),(a,b)=>(a._definition || a.definition) === (b.definition || b._id)).length === 0
    const extrasToFilter = isStationExtras ? product.extras : oldExtras;
    $scope.oneWayExtraOptionsDefinitions = filterExtraOptionsDefinitions('oneWay',extrasToFilter);
    $scope.twoWayExtraOptionsDefinitions = filterExtraOptionsDefinitions('twoWay',extrasToFilter);
    $scope.upgradeExtraOptionsDefinitions = filterExtraOptionsDefinitions('upgrade', extrasToFilter);
  }
  setProduct(product);

  $scope.getProductTitle = function (product) {
    product = product || $scope.product;
    return product.type + ' from ' + product.from.city.name + ' to ' + product.to.city.name + ' (' + product.denomination + ')';
  };

  $scope.getDepartureTimezone = function () {
    // Line
    if ($scope.product.from) {
      return $scope.product.from.city.timezone;
    } else {
      return $scope.product.itinerary[0].city.timezone;
    }
  };

  $scope.getArrivalTimezone = function () {
    // Line
    if ($scope.product.to) {
      return $scope.product.to.city.timezone;
    } else {
      return $scope.product.itinerary[0].city.timezone;
    }
  };

  $scope.getType = getType;

  function bookingsSave() {
    if ($scope.tickBookingForm.$invalid) {
      $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'Form has errors' });
      console.log($scope.tickBookingForm);
      return;
    }
    if ($scope.booking._id) {
      $uibModal
        .open({
          templateUrl: 'views/bookings/edit/modal.html',
          controller: ['$scope', 'product', 'originalProduct', '$uibModalInstance', function ($scope, product, originalProduct, $uibModalInstance) {
            $scope.product = product;
            $scope.company = product.company;
            $scope.oldCompany = product.company._id !== originalProduct.company._id ? originalProduct.company : null;

            $scope.productChanged = product.company._id !== originalProduct.company._id;
            $scope.notifications = {
              update: false,
              cancel: false,
              create: false
            };
            $scope.yes = function () {
              $scope.notifications.update = true;
              $uibModalInstance.close($scope.notifications);
            };

            $scope.no = function () {
              $scope.notifications.update = false;
              $uibModalInstance.close($scope.notifications);
            };

            $scope.save = function () {
              $scope.notifications.update = false;
              $uibModalInstance.close($scope.notifications);
            };
          }],
          resolve: {
            product: [function () {
              return $scope.product;
            }],
            originalProduct: [function () {
              return product;
            }]
          },
        })
        .result
        .then(function (notifications) {
          $scope.payload.notifySupplier = notifications.update;
          $scope.payload.notifyCancelForPreviousSupplier = notifications.cancel;
          $scope.payload.notifyCreatedForNewSupplier = notifications.create;
          $scope.submit();
        });
    } else {
      $uibModal
        .open({
          templateUrl: 'views/are-you-sure-modal.html',
          controller: 'AreYouSureCtrl',
          resolve: {
            text: [function () {
              if ($scope.bookingForm.markBookingAsPaid) {
                return 'Please note that relevant emails will be sent to the suppliers & customer';
              } else {
                return null;
              }
            }],
            title: [function () { return 'Are you sure?'; }]
          }
        })
        .result
        .then(function (response) {
          if (response) {
            $scope.submit();
          }
        });
    }
  }

  const unregisterSaving = $rootScope.$on('booking.save', bookingsSave);
  $scope.$on('$destroy', unregisterSaving);

  $scope.canChangeProduct = function () {
    var message = 'error';
    var result = true;
    if (result && !$scope.booking._id) {
      result = false;
      message = 'Exchange booking is only for existing booking';
    }
    var isSettled = !!$scope.booking.initialPrice;
    var isAllSettled = true;
    if (result && $scope.booking.additionalTransactions) {
      isAllSettled = $scope.booking.additionalTransactions.every(function (item) {
        return item.isSettled;
      });
    }
    if (result && (!isSettled || !isAllSettled)) {
      result = false;
      message = 'All transactions must be settled on original booking';
    }
    if (result && !['paid', 'refund', 'authorized'].includes($scope.booking.payment.status)) {
      result = false;
      message = 'Booking must be paid in order to exchange';
    }
    if ($scope.booking.exchangedBy){
      result = false;
      message = 'Booking was already successfuly exchange with booking: ' + $scope.booking.exchangedBy;
    }
    return {
      result: result,
      message: message,
    };
  };

  $scope.disableChangeProduct = $scope.canChangeProduct();

  $scope.changeProduct = function () {
    var isChangeProductAllowed = $scope.canChangeProduct();
    if (!isChangeProductAllowed.result) {
      $scope.$emit('notify', {
        type: 'error',
        title: 'Exchange booking',
        message: isChangeProductAllowed.message
      });
      return;
    }
    changeBookingLineModal.show($scope.booking.basicCurrency)
      .then(function (product) {
        $scope.oldBooking = $scope.booking;
        $scope.booking = {};
        setProduct(product, true);
      });
  };

  $scope.switchDirections = function () {
    var from = $scope.product.from.city;
    var to = $scope.product.to.city;
    Restangular
      .all('routes').getList({ from: to._id, to: from._id, limit: 0 })
      .then(function (routes) {
        let errorOccurred = false;
        routes = routes.filter(function (route) {
          return route.line._id === $scope.product.line._id;
        });
        if (routes.length === 1){
          setProduct(routes[0], true);
        } else if (routes.length === 2){
          const currentTransferId = $scope.product.direction === 'fromAtoB' ? $scope.product.line.aToBTransferId : $scope.product.line.bToATransferId;
          const selectedRoute = routes.find(function(route){
            const routeTransferId = route.direction === 'fromAtoB' ? route.line.aToBTransferId : route.line.bToATransferId;
            return routeTransferId !== currentTransferId;
          });
          if (selectedRoute){
            setProduct(selectedRoute, true);
          } else {

            errorOccurred = true;
          }
        } else {
          errorOccurred = true;
        }
        if (errorOccurred){
          $scope.$emit('notify', {
            type: 'error',
            title: 'Switch direction',
            message: 'Couldn\'t find a line iin th opposite direction'
          });
        }
      })
      .catch(function () {
        $scope.$emit('notify', { type: 'error', title: 'Switch direction', message: 'Failed to switch direction' });
      });
  };

  $scope.submit = function () {
    if ($scope.tickBookingForm.$invalid) {
      $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'Form has errors' });
      console.log($scope.tickBookingForm);
      return;
    }

    if ($scope.booking.isSupplierApi) {
      $scope.$emit('notify', { type: 'info', title: 'Booking', message: 'Only Email, Phone, and Fraud fields will be updated for supplierAPI booking' });
    }

    if (_.get($scope.booking, 'items[0].productType') !== 'online' && (!$scope.fromStationId || !$scope.toStationId)) {
      $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'One or more stations on departure journey are empty' });
      return;
    }

    if ($scope.bookingForm.roundTrip && (!$scope.returnFromStationId || !$scope.returnToStationId)) {
      $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'One or more stations on departure journey are empty' });
      return;
    }

    if ($scope.fromStationId && $scope.toStationId && $scope.fromStationId === $scope.toStationId) {
      $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'Pickup station and drop-off station must be different' });
      return;
    }

    if ($scope.returnFromStationId && $scope.returnToStationId && $scope.returnFromStationId === $scope.returnToStationId) {
      $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'Pickup station and drop-off station must be different' });
      return;
    }

    var oneWayExtraOptions = $scope.isNewExtras ? $scope.bookingForm.oneWayExtraOptions : filterOldItemExtraOptions($scope.bookingForm.extraOptions);

    var isNewBooking = !$scope.booking._id;
    const productType = $scope.product.productType || _.get(booking, 'items.0.productType');
    $scope.payload.items = [computeItem($scope.product, $scope.bookingForm.journeyOne, $scope.bookingForm.passengers, oneWayExtraOptions, isNewBooking, productType)];

    if ($scope.bookingForm.roundTrip) {
      var twoWayExtraOptions = $scope.isNewExtras ? $scope.bookingForm.twoWayExtraOptions : filterOldItemExtraOptions($scope.bookingForm.extraOptions);
      $scope.payload.items.push(
        computeItem($scope.product, $scope.bookingForm.journeyTwo, $scope.bookingForm.passengers, twoWayExtraOptions, isNewBooking, productType)
      );
    }

    if ($scope.bookingForm.domain) {
      $scope.payload.domain = $scope.bookingForm.domain;
    }

    // Add extra options per booking
    $scope.payload.extraOptions = $scope.isNewExtras ? $scope.bookingForm.upgradeExtraOptions : filterOldBookingExtraOptions($scope.bookingForm.extraOptions);

    var promise;
    var prePostBookingPrice = $scope.booking.price;

    if ($scope.booking._id) {
      // Not sure we need this
      Object.assign($scope.payload, {
        reference: $scope.booking.reference,
        _id: $scope.booking._id,
        status: $scope.bookingForm.status,
        amendStatus: $scope.bookingForm.amendStatus,
        payment: $scope.booking.payment,
      });

      $scope.payload.payment.status = $scope.bookingForm.payment;

      if ($scope.bookingForm.customerStatus) {
        $scope.payload.customerStatus = $scope.bookingForm.customerStatus;
      }

      if ($scope.fromStationId && $scope.toStationId) {
        $scope.payload.items[0].from = $scope.fromStationId;
        $scope.payload.items[0].to = $scope.toStationId;
      }
      if ($scope.returnFromStationId && $scope.returnToStationId && $scope.bookingForm.roundTrip) {
        $scope.payload.items[1].from = $scope.returnFromStationId;
        $scope.payload.items[1].to = $scope.returnToStationId;
      }

      if ($scope.payload.status === 'pending' && $scope.booking.status !== 'pending' && $scope.booking.inProgressBy) {
        $scope.payload.inProgressBy = null;
      }

      promise = (Restangular.restangularizeElement(null, $scope.payload, 'v2/bookings')).put();
    } else {
      if ($scope.oldBooking._id) {
        $scope.payload.oldBookingId = $scope.oldBooking._id;
        prePostBookingPrice = $scope.oldBooking.price;
      } else if ($scope.bookingForm.markBookingAsPaid && $scope.bookingForm.application && $scope.bookingForm.application.id) {
        $scope.payload.markBookingAsPaid = true;
        $scope.payload.application = {
          id: $scope.bookingForm.application.id
        };
      }
      if ($scope.fromStationId && $scope.toStationId) {
        $scope.payload.items[0].fromStation = $scope.fromStationId;
        $scope.payload.items[0].toStation = $scope.toStationId;
        $scope.payload.items[0].fromSlug = $scope.product.from.city.slug;
        $scope.payload.items[0].toSlug = $scope.product.to.city.slug;
      }
      if ($scope.returnFromStationId && $scope.returnToStationId) {
        $scope.payload.items[1].from = $scope.returnFromStationId;
        $scope.payload.items[1].to = $scope.returnToStationId;
      }
      promise = Restangular
        .all('v2/bookings')
        .customPOST($scope.payload, null, null, {"X-Distribution-Channel": $scope.payload.domain ? $scope.payload.domain : 'bookaway'});
    }
    promise
      .then(function (booking) {
        $scope.$emit('notify', { type: 'success', title: 'Booking', message: 'Booking saved with success' });
        if (prePostBookingPrice && prePostBookingPrice.amount !== booking.price.amount) {
          $uibModal
            .open({
              templateUrl: 'views/payments/change-price.html',
              controller: 'PaymentChangePriceCtrl',
              resolve: {
                booking: [function () {
                  return booking;
                }],
                oldBooking: [function () {
                  if ($scope.oldBooking && $scope.oldBooking._id) {
                    return $scope.oldBooking;
                  } else {
                    return $scope.booking;
                  }
                }],
                paymentCycles: [function() {
                  return [];
                }],
                user: [function() {
                  return user;
                }],
                changePriceReasons: [function(){
                  return CHANGE_PRICE_REASONS.BOOKING_AMENDMENT;
                }],
              }
            })
            .result
            .then(function (booking) {
              $scope.$emit('notify', { type: 'success', title: 'Booking', message: 'updated booking price' });
              // $state.go('root.editBooking', {bookingId: booking._id});
              $state.go('root.bookings', { booking: booking._id });
            })
            .catch(function (err) {
              $scope.$emit('notify', {
                type: 'error',
                title: 'Booking',
                message: 'Booking price was not updated',
              });
              // $state.go('root.editBooking', {bookingId: booking._id});
              $state.go('root.bookings', { booking: booking._id });
            });
        } else {
          // $state.go('root.editBooking', {bookingId: booking._id});
          $state.go('root.bookings', { booking: booking._id });
        }
      })
      .catch(function (error) {
        var message = error.data ? error.data.message : '';
        $scope.$emit('notify', { type: 'error', title: 'Booking', message: 'Failed to save booking:' + message });
      });
  };
}

"use strict";

/* exported
bookingSupplierDataCtrl
 */
function bookingSupplierDataCtrl(
  $scope,
  booking,
  bookingSupplierData,
  $uibModalInstance
) {
  $scope.booking = booking;
  $scope.bookingSupplierData = bookingSupplierData;

  $scope.close = function () {
    $uibModalInstance.close();
  };
}

"use strict";

/* exported
rideRulesTableDirective
 */

function rideRulesTableDirective($timeout, InventoryRestangular, RideRulesService, TransportEditService, supplierApiService, $uibModal, appConfig, RIDE_RULE_LEVELS, RIDE_RULE_ATTRIBUTE_TYPES) {
    return {
        restrict: "EA",
        scope: {
            rulesAttribute: "=",
            filters: "=",
            supplierCurrency: "=",
            conditionColumn: "=",
            supplierCompanyId: "=",
            ruleResource: "=",
            ruleResourceType: "=", // transport, operator, supplier
        },
        templateUrl: "views/inventory/ride-rules-table.directive.html",
        link: function (scope, iElement, iAttrs, ctrls) {
            scope.rideRulesService = RideRulesService;
            scope.rideRuleLevels = RIDE_RULE_LEVELS;
            scope.rideRuleAttributeTypes = RIDE_RULE_ATTRIBUTE_TYPES;
            scope.isOperatorPage = false;
            scope.isTransportPage = _.get(scope, 'ruleResourceType.type') === RIDE_RULE_LEVELS.TRANSPORT;

            scope.getSourceBadgeStyle = function (source) {
                const colorMap = {
                    [RIDE_RULE_LEVELS.TRANSPORT]: { color: "#9747FF", 'background-color': "#EEE2FE" },
                    [RIDE_RULE_LEVELS.OPERATOR]: { color: "#0F5EC9", 'background-color': "#D5E4F8" },
                    [RIDE_RULE_LEVELS.SUPPLIER]: { color: "#1A495A", 'background-color': "rgba(26, 73, 90, 0.2)" },
                    [RIDE_RULE_LEVELS.API]: { color: "#B3B3B3", 'background-color': "#F0F0F0" },
                    default: { color: "#0F5EC9", 'background-color': "#D5E4F8" }
                };
                return colorMap[source] || colorMap.default;
            };

            scope.isShowRulePopup = false;
            scope.rulePopupContent = "";
            scope.isOverPopup = false; // Track if the mouse is over the popup

            scope.deleteRule = function (selectedRuleAttr, rule) {
                let id = selectedRuleAttr.rules[0]._id;
                if(rule) {
                    id = rule._id;
                }
                InventoryRestangular.one(`/ride-rules/${id}`).remove()
                  .then(function (res) {
                    if(res){
                        scope.rulesAttribute.map((attr) => {
                            attr.rules = attr.rules.filter((rule) => {
                                return rule._id !== id;
                            });
                        });
                    }
                  })
                  .catch(function () {
                  }
                );
            };

            scope.openEditAddRuleModal = function (action, attribute, source, rule) {

                let index = -1;
                if(action=== 'edit' && rule && attribute.rules && attribute.rules.length >= 1) {
                    index = _.findIndex(attribute.rules, {_id: rule._id});
                }

                $uibModal
                  .open({
                    templateUrl: 'views/lines/rule-modal.html',
                    controller: 'ruleModalCtrl',
                    windowClass: 'edit-add-rule-modal',
                    resolve: {
                        //we send this because, in case we are in operator rule we don't have the supplier
                        supplierSource:  function () {
                            if(_.get(supplierApiService,'rulesAttributeFilters.operator')) {
                                return scope.ruleResource;
                            }
                        },
                        ruleResource: function () {
                            if(_.get(supplierApiService,'rulesAttributeFilters.operator')) {
                                return supplierApiService.rulesAttributeFilters.operator;
                            }else {
                                return scope.ruleResource;
                            }
                        },
                       ruleResourceType: function () {
                            if (source) {
                                if(source === RIDE_RULE_LEVELS.SUPPLIER && scope.isOperatorPage && action === 'add') {
                                    return {type: RIDE_RULE_LEVELS.OPERATOR}
                                } 
                                if(source === RIDE_RULE_LEVELS.OPERATOR && scope.isTransportPage && action === 'add') {
                                    return {type: RIDE_RULE_LEVELS.TRANSPORT}
                                } 
                                if(source === RIDE_RULE_LEVELS.API) {
                                    if (scope.isOperatorPage) {
                                        return {type: RIDE_RULE_LEVELS.OPERATOR};
                                    }
                                    if (scope.isTransportPage) {
                                        return {type: RIDE_RULE_LEVELS.TRANSPORT}
                                    } 
                                    return {type: RIDE_RULE_LEVELS.SUPPLIER}
                                }
                                return {type: source.charAt(0) + source.substring(1).toLowerCase()}
                            }else if (supplierApiService.rulesAttributeFilters.operator) {
                                return {type: RIDE_RULE_LEVELS.OPERATOR}
                            }else {
                                return scope.ruleResourceType
                            }
                       },
                       attribute,
                       index,
                       action: function () { return action; },
                       rideRulesService: RideRulesService,
                       supplierCurrency: function () { return scope.supplierCurrency; },
                  }
                 }).result
                 .then(function(result) {
                    if (result && result.rule){
                        
                        if(result.rule.source === RIDE_RULE_LEVELS.TRANSPORT) {
                            scope.rulesAttribute = TransportEditService.refreshRules();
                        }else {
                            scope.rulesAttribute  = RideRulesService.getRules(supplierApiService);
                        }
                    }
                });
            };

            scope.capturedSource = null;

            scope.captureSource = function(source) {
                scope.capturedSource = source;
            };


            scope.showRulePopup = function (event, ruleAttribute, rule) {
                scope.isOperatorPage = supplierApiService.rulesAttributeFilters.operator;
                if(!appConfig.editAddDeleteRideRules) {
                    return;
                }
                // If trying to edit hardcoded attributes, don't show popup
                if (RideRulesService.hardcodedAttributes.includes(ruleAttribute.slug)) {
                    // Hide the popup
                    scope.isShowRulePopup = false;
                    return;
                }
                // If hovering over cell that displays overriding rules, don't show popup
                var targetElement = event.target;
                if (targetElement.className && targetElement.className.toString().includes("overriding-rules")) {
                    // Hide the popup
                    scope.isShowRulePopup = false;
                    return;
                }
                const row = targetElement.closest("tr");
                const rowRect = row.getBoundingClientRect();
                const sourceCellRect = row.querySelector(".source-cell").getBoundingClientRect();

                // Calculate the position of the popup relative to the row
                const popupTop = rowRect.top + window.scrollY - 100;
                const popupLeft = rowRect.left + rowRect.width + window.scrollX - (sourceCellRect.width / 2);

                // Set the position and content of the popup
                scope.popupStyle = {
                    top: popupTop + "px",
                    left: popupLeft + "px",
                    backgroundColor: "white",
                    boxShadow: "0 4px 10px rgba(0, 29, 39, 0.08)",
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    padding: "15px 0px 0px 15px",
                    borderRadius: "4px",
                };
                scope.selectedRuleAttr  = ruleAttribute;
                scope.selectedRule = rule;
                // Show the popup
                scope.isShowRulePopup = true;
            };


            scope.hideRulePopup = function () {
                // Hide the popup
                if (!scope.isOverPopup && !scope.isOverTable) {
                    scope.isShowRulePopup = false;
                }
            };
            scope.keepRulePopup = function () {
                scope.isOverPopup = true; // Mouse is over the popup
            };

            scope.onMouseEnterTable = function () {
                scope.isOverTable = true; // Mouse is over the table
            };

            scope.onMouseLeaveTable = function () {
                scope.isOverTable = false; // Mouse is not over the table
                $timeout(scope.hideRulePopup, 100) // Hide the popup after a short delay
            };

            scope.onMouseEnterPopup = function () {
                scope.keepRulePopup(); // Mouse is over the popup
            };

            scope.onMouseLeavePopup = function () {
                scope.isOverPopup = false; // Mouse is not over the popup
                scope.hideRulePopup(); // Hide the popup
            };

            scope.getAttributeCellRowSpan = function(ruleAttribute) {
                return Math.max(ruleAttribute.rules.length, 1) + (ruleAttribute.overridingRules && 1)
            }

            scope.isDisplayRuleAttribute = function (ruleAttribute) {
                return scope.filters.category === RideRulesService.defaultRuleCategory || scope.filters.category === ruleAttribute.category
            }
        },
    };
}
